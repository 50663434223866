import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TRANSIT_AGENCY_SUMMARY,
  REQUEST_GET_TRANSIT_AGENCY_SUMMARY,
  REQUEST_GET_TRANSIT_AGENCY_SUMMARY_FAILURE
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import MobipixUtils from 'util/MobipixUtils';

export const getTransitAgencySummary = (startDatetime, endDatetime, transportOperatorIdsArray, transportOperatorNamesArray) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const transportOperatorIdsFilterQueryParam = MobipixUtils.getTransportOperatorIdsFilterQueryParam(transportOperatorIdsArray);
  const transportOperatorNamesFilterQueryParam = MobipixUtils.getTransportOperatorNamesFilterQueryParam(transportOperatorNamesArray);

  return async dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSIT_AGENCY_SUMMARY });
    try {
      const res = await mobipixApi.get(`dashboard/transit-agency/summary?${startDatetimeStr}${endDatetimeStr}${transportOperatorIdsFilterQueryParam}${transportOperatorNamesFilterQueryParam}`);
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: GET_TRANSIT_AGENCY_SUMMARY, payload: res.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: FETCH_ERROR });
      dispatch({ type: REQUEST_GET_TRANSIT_AGENCY_SUMMARY_FAILURE });
    }
  };
};
