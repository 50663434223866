
import {
    SIGN_OUT,
    GET_ACTIVE_TRIPS,
    GET_PENDING_TRIPS,
    GET_FINISHED_TRIPS,
    GET_ERROR_TRIPS,
    GET_NOT_DONE_TRIPS,
    GET_EARLY_START_TRIPS,
    GET_LATE_START_TRIPS,
    GET_ON_TIME_START_TRIPS,
    GET_EARLY_END_TRIPS,
    GET_LATE_END_TRIPS,
    GET_ON_TIME_END_TRIPS,
    GET_SCHEDULED_TRIPS_TAKEN,
    GET_TRIPS_PUNCTUALITY_START_INDEX,
    GET_TRIPS_PUNCTUALITY_END_INDEX,
    GET_TRIPS_PUNCTUALITY_INDEX,
    REQUEST_GET_ACTIVE_TRIPS,
    REQUEST_GET_PENDING_TRIPS,
    REQUEST_GET_FINISHED_TRIPS,
    REQUEST_GET_ERROR_TRIPS,
    REQUEST_GET_NOT_DONE_TRIPS,
    REQUEST_GET_EARLY_START_TRIPS,
    REQUEST_GET_LATE_START_TRIPS,
    REQUEST_GET_ON_TIME_START_TRIPS,
    REQUEST_GET_EARLY_END_TRIPS,
    REQUEST_GET_LATE_END_TRIPS,
    REQUEST_GET_ON_TIME_END_TRIPS,
    REQUEST_GET_SCHEDULED_TRIPS_TAKEN,
    REQUEST_GET_TRIPS_PUNCTUALITY_START_INDEX,
    REQUEST_GET_TRIPS_PUNCTUALITY_END_INDEX,
    REQUEST_GET_TRIPS_PUNCTUALITY_INDEX,
    REQUEST_GET_ACTIVE_TRIPS_FAILURE,
    REQUEST_GET_PENDING_TRIPS_FAILURE,
    REQUEST_GET_FINISHED_TRIPS_FAILURE,
    REQUEST_GET_ERROR_TRIPS_FAILURE,
    REQUEST_GET_NOT_DONE_TRIPS_FAILURE,
    REQUEST_GET_EARLY_START_TRIPS_FAILURE,
    REQUEST_GET_LATE_START_TRIPS_FAILURE,
    REQUEST_GET_ON_TIME_START_TRIPS_FAILURE,
    REQUEST_GET_EARLY_END_TRIPS_FAILURE,
    REQUEST_GET_LATE_END_TRIPS_FAILURE,
    REQUEST_GET_ON_TIME_END_TRIPS_FAILURE,
    REQUEST_GET_SCHEDULED_TRIPS_TAKEN_FAILURE,
    REQUEST_GET_TRIPS_PUNCTUALITY_START_INDEX_FAILURE,
    REQUEST_GET_TRIPS_PUNCTUALITY_END_INDEX_FAILURE,
    REQUEST_GET_TRIPS_PUNCTUALITY_INDEX_FAILURE
} from 'constants/ActionTypes';

const INIT_STATE = {
    activeTrips: { tripsTotal: 0 },
    pendingTrips: { tripsTotal: 0 },
    finishedTrips: { tripsTotal: 0 },
    errorTrips: { tripsTotal: 0 },
    notDoneTrips: { tripsTotal: 0 },
    earlyStartTrips: { tripsTotal: 0 },
    lateStartTrips: { tripsTotal: 0 },
    onTimeStartTrips: { tripsTotal: 0 },
    earlyEndTrips: { tripsTotal: 0 },
    lateEndTrips: { tripsTotal: 0 },
    onTimeEndTrips: { tripsTotal: 0 },
    scheduledTripsTaken: { tripsTotal: 0 },
    tripsPunctualityStartIndex: { tripsTotal: 0 },
    tripsPunctualityEndIndex: { tripsTotal: 0 },
    tripsPunctualityIndex: { tripsTotal: 0 },
    loadingActiveTrips: false,
    loadingActiveTripsError: false,
    loadingPendingTrips: false,
    loadingPendingTripsError: false,
    loadingFinishedTrips: false,
    loadingFinishedTripsError: false,
    loadingErrorTrips: false,
    loadingErrorTripsError: false,
    loadingNotDoneTrips: false,
    loadingNotDoneTripsError: false,
    loadingEarlyStartTrips: false,
    loadingEarlyStartTripsError: false,
    loadingLateStartTrips: false,
    loadingLateStartTripsError: false,
    loadingOnTimeStartTrips: false,
    loadingOnTimeStartTripsError: false,
    loadingEarlyEndTrips: false,
    loadingEarlyEndTripsError: false,
    loadingLateEndTrips: false,
    loadingLateEndTripsError: false,
    loadingOnTimeEndTrips: false,
    loadingOnTimeEndTripsError: false,
    loadingScheduledTripsTaken: false,
    loadingScheduledTripsTakenError: false,
    loadingTripsPunctualityStartIndex: false,
    loadingTripsPunctualityStartIndexError: false,
    loadingTripsPunctualityEndIndex: false,
    loadingTripsPunctualityEndIndexError: false,
    loadingTripsPunctualityIndex: false,
    loadingTripsPunctualityIndexError: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGN_OUT: {
            return INIT_STATE;
        }

        case GET_ACTIVE_TRIPS: {
            return {
                ...state,
                activeTrips: action.payload,
                loadingActiveTrips: false,
                loadingActiveTripsError: false
            };
        }

        case REQUEST_GET_ACTIVE_TRIPS: {
            return {
                ...state,
                loadingActiveTrips: true,
                loadingActiveTripsError: false
            }
        }

        case REQUEST_GET_ACTIVE_TRIPS_FAILURE: {
            return {
                ...state,
                loadingActiveTrips: false,
                loadingActiveTripsError: true
            }
        }

        case GET_PENDING_TRIPS: {
            return {
                ...state,
                pendingTrips: action.payload,
                loadingPendingTrips: false,
                loadingPendingTripsError: false
            };
        }

        case REQUEST_GET_PENDING_TRIPS: {
            return {
                ...state,
                loadingPendingTrips: true,
                loadingPendingTripsError: false
            }
        }

        case REQUEST_GET_PENDING_TRIPS_FAILURE: {
            return {
                ...state,
                loadingPendingTrips: false,
                loadingPendingTripsError: true
            }
        }

        case GET_FINISHED_TRIPS: {
            return {
                ...state,
                finishedTrips: action.payload,
                loadingFinishedTrips: false,
                loadingFinishedTripsError: false
            };
        }

        case REQUEST_GET_FINISHED_TRIPS: {
            return {
                ...state,
                loadingFinishedTrips: true,
                loadingFinishedTripsError: false
            }
        }

        case REQUEST_GET_FINISHED_TRIPS_FAILURE: {
            return {
                ...state,
                loadingFinishedTrips: false,
                loadingFinishedTripsError: true
            }
        }

        case GET_ERROR_TRIPS: {
            return {
                ...state,
                errorTrips: action.payload,
                loadingErrorTrips: false,
                loadingErrorTripsError: false
            };
        }

        case REQUEST_GET_ERROR_TRIPS: {
            return {
                ...state,
                loadingErrorTrips: true,
                loadingErrorTripsError: false
            }
        }

        case REQUEST_GET_ERROR_TRIPS_FAILURE: {
            return {
                ...state,
                loadingErrorTrips: false,
                loadingErrorTripsError: true
            }
        }

        case GET_NOT_DONE_TRIPS: {
            return {
                ...state,
                notDoneTrips: action.payload,
                loadingNotDoneTrips: false,
                loadingNotDoneTripsError: false
            };
        }

        case REQUEST_GET_NOT_DONE_TRIPS: {
            return {
                ...state,
                loadingNotDoneTrips: true,
                loadingNotDoneTripsError: false
            }
        }

        case REQUEST_GET_NOT_DONE_TRIPS_FAILURE: {
            return {
                ...state,
                loadingNotDoneTrips: false,
                loadingNotDoneTripsError: true
            }
        }

        case GET_EARLY_START_TRIPS: {
            return {
                ...state,
                earlyStartTrips: action.payload,
                loadingEarlyStartTrips: false,
                loadingEarlyStartTripsError: false
            };
        }

        case REQUEST_GET_EARLY_START_TRIPS: {
            return {
                ...state,
                loadingEarlyStartTrips: true,
                loadingEarlyStartTripsError: false
            }
        }

        case REQUEST_GET_EARLY_START_TRIPS_FAILURE: {
            return {
                ...state,
                loadingEarlyStartTrips: false,
                loadingEarlyStartTripsError: true
            }
        }

        case GET_LATE_START_TRIPS: {
            return {
                ...state,
                lateStartTrips: action.payload,
                loadingLateStartTrips: false,
                loadingLateStartTripsError: false
            };
        }

        case REQUEST_GET_LATE_START_TRIPS: {
            return {
                ...state,
                loadingLateStartTrips: true,
                loadingLateStartTripsError: false
            }
        }

        case REQUEST_GET_LATE_START_TRIPS_FAILURE: {
            return {
                ...state,
                loadingLateStartTrips: false,
                loadingLateStartTripsError: true
            }
        }

        case GET_ON_TIME_START_TRIPS: {
            return {
                ...state,
                onTimeStartTrips: action.payload,
                loadingOnTimeStartTrips: false,
                loadingOnTimeStartTripsError: false
            };
        }

        case REQUEST_GET_ON_TIME_START_TRIPS: {
            return {
                ...state,
                loadingOnTimeStartTrips: true,
                loadingOnTimeStartTripsError: false
            }
        }

        case REQUEST_GET_ON_TIME_START_TRIPS_FAILURE: {
            return {
                ...state,
                loadingOnTimeStartTrips: false,
                loadingOnTimeStartTripsError: true
            }
        }

        case GET_EARLY_END_TRIPS: {
            return {
                ...state,
                earlyEndTrips: action.payload,
                loadingEarlyEndTrips: false,
                loadingEarlyEndTripsError: false
            };
        }

        case REQUEST_GET_EARLY_END_TRIPS: {
            return {
                ...state,
                loadingEarlyEndTrips: true,
                loadingEarlyEndTripsError: false
            }
        }

        case REQUEST_GET_EARLY_END_TRIPS_FAILURE: {
            return {
                ...state,
                loadingEarlyEndTrips: false,
                loadingEarlyEndTripsError: true
            }
        }

        case GET_LATE_END_TRIPS: {
            return {
                ...state,
                lateEndTrips: action.payload,
                loadingLateEndTrips: false,
                loadingLateEndTripsError: false
            };
        }

        case REQUEST_GET_LATE_END_TRIPS: {
            return {
                ...state,
                loadingLateEndTrips: true,
                loadingLateEndTripsError: false
            }
        }

        case REQUEST_GET_LATE_END_TRIPS_FAILURE: {
            return {
                ...state,
                loadingLateEndTrips: false,
                loadingLateEndTripsError: true
            }
        }

        case GET_ON_TIME_END_TRIPS: {
            return {
                ...state,
                onTimeEndTrips: action.payload,
                loadingOnTimeEndTrips: false,
                loadingOnTimeEndTripsError: false
            };
        }

        case REQUEST_GET_ON_TIME_END_TRIPS: {
            return {
                ...state,
                loadingOnTimeEndTrips: true,
                loadingOnTimeEndTripsError: false
            }
        }

        case REQUEST_GET_ON_TIME_END_TRIPS_FAILURE: {
            return {
                ...state,
                loadingOnTimeEndTrips: false,
                loadingOnTimeEndTripsError: true
            }
        }

        case GET_SCHEDULED_TRIPS_TAKEN: {
            return {
                ...state,
                scheduledTripsTaken: action.payload,
                loadingScheduledTripsTaken: false,
                loadingScheduledTripsTakenError: false
            };
        }

        case REQUEST_GET_SCHEDULED_TRIPS_TAKEN: {
            return {
                ...state,
                loadingScheduledTripsTaken: true,
                loadingScheduledTripsTakenError: false
            }
        }

        case REQUEST_GET_SCHEDULED_TRIPS_TAKEN_FAILURE: {
            return {
                ...state,
                loadingScheduledTripsTaken: false,
                loadingScheduledTripsTakenError: true
            }
        }

        case GET_TRIPS_PUNCTUALITY_START_INDEX: {
            return {
                ...state,
                tripsPunctualityStartIndex: action.payload,
                loadingTripsPunctualityStartIndex: false,
                loadingTripsPunctualityStartIndexError: false
            };
        }

        case REQUEST_GET_TRIPS_PUNCTUALITY_START_INDEX: {
            return {
                ...state,
                loadingTripsPunctualityStartIndex: true,
                loadingTripsPunctualityStartIndexError: false
            }
        }

        case REQUEST_GET_TRIPS_PUNCTUALITY_START_INDEX_FAILURE: {
            return {
                ...state,
                loadingTripsPunctualityStartIndex: false,
                loadingTripsPunctualityStartIndexError: true
            }
        }

        case GET_TRIPS_PUNCTUALITY_END_INDEX: {
            return {
                ...state,
                tripsPunctualityEndIndex: action.payload,
                loadingTripsPunctualityEndIndex: false,
                loadingTripsPunctualityEndIndexError: false
            };
        }

        case REQUEST_GET_TRIPS_PUNCTUALITY_END_INDEX: {
            return {
                ...state,
                loadingTripsPunctualityEndIndex: true,
                loadingTripsPunctualityEndIndexError: false
            }
        }

        case REQUEST_GET_TRIPS_PUNCTUALITY_END_INDEX_FAILURE: {
            return {
                ...state,
                loadingTripsPunctualityEndIndex: false,
                loadingTripsPunctualityEndIndexError: true
            }
        }

        case GET_TRIPS_PUNCTUALITY_INDEX: {
            return {
                ...state,
                tripsPunctualityIndex: action.payload,
                loadingTripsPunctualityIndex: false,
                loadingTripsPunctualityIndexError: false
            };
        }

        case REQUEST_GET_TRIPS_PUNCTUALITY_INDEX: {
            return {
                ...state,
                loadingTripsPunctualityIndex: true,
                loadingTripsPunctualityIndexError: false
            }
        }

        case REQUEST_GET_TRIPS_PUNCTUALITY_INDEX_FAILURE: {
            return {
                ...state,
                loadingTripsPunctualityIndex: false,
                loadingTripsPunctualityIndexError: true
            }
        }

        default: {
            return state;
        }
    }
}