import {
  SIGN_OUT,
  GET_TRANSPORT_OPERATOR_SUMMARY,
  REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY,
  REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY_FAILURE
} from "../constants/ActionTypes";

const INIT_STATE = {
  dashboard: {
    transportOperatorSummary: {
      transportOperatorName: '',
      authorizedGetOnsTotal: 0,
      authorizedGetOnsTotalAmount: 0,
      linesTotal: 0,
      vehiclesTotal: 0,
      onboardDevicesTotal: 0,
      tripsTotal: 0,
      driversTotal: 0
    },
    loadingTransportOperatorSummary: false,
    loadingTransportOperatorSummaryError: false
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_TRANSPORT_OPERATOR_SUMMARY: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          transportOperatorSummary: action.payload,
          loadingTransportOperatorSummary: false,
          loadingTransportOperatorSummaryError: false
        },
      };
    }

    case REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loadingTransportOperatorSummary: true,
          loadingTransportOperatorSummaryError: false
        }
      }
    }

    case REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY_FAILURE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loadingTransportOperatorSummary: false,
          loadingTransportOperatorSummaryError: true
        }
      }
    }

    default: {
      return state;
    }
  }
}
