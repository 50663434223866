import {

  SIGN_OUT,

  GET_PAYMENT_GATEWAY_SUMMARY,

} from "../constants/ActionTypes";

const INIT_STATE = {

  dashboard: {
    paymentGatewaySummary: {
      paymentGatewayName: '',
      paymentGatewayBalance: 0,
      authorizedGetOnsTotal: 0,
      authorizedGetOnsTotalAmount: 0,
      linesTotal: 0,
      vehiclesTotal: 0,
    }
  },

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_PAYMENT_GATEWAY_SUMMARY: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          paymentGatewaySummary: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
