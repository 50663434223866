import {
  SIGN_IN,
  SIGN_OUT,
  RESET_MOBIPIX_PWD_RESULT,
} from 'constants/ActionTypes';

const STORAGE_ITEM_TOKEN = 'token';
const STORAGE_ITEM_USER = 'user';

const clearLocalStorage = () => {
  localStorage.removeItem(STORAGE_ITEM_TOKEN);
  localStorage.removeItem(STORAGE_ITEM_USER);
};

const INIT_STATE = {
  isLoggedIn: !!localStorage.getItem(STORAGE_ITEM_TOKEN),
  token: localStorage.getItem(STORAGE_ITEM_TOKEN),
  authUser: JSON.parse(localStorage.getItem(STORAGE_ITEM_USER)),
  resetMobipixPwdResult: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_IN: {
      const { token, authUser } = action.payload;

      localStorage.setItem(STORAGE_ITEM_TOKEN, token);
      localStorage.setItem(STORAGE_ITEM_USER, JSON.stringify(authUser));

      return {
        ...state,
        isLoggedIn: true,
        token: token,
        authUser: authUser,
      };
    }

    case SIGN_OUT: {
      clearLocalStorage();
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        authUser: null,
        resetMobipixPwdResult: false,
      };
    }

    case RESET_MOBIPIX_PWD_RESULT: {
      return {
        ...state,
        resetMobipixPwdResult: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
