import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorView from 'util/ErrorView';
import { QueryClient, QueryClientProvider } from 'react-query';
import configureStore, { history } from './store';
// import './firebase/firebase';
import App from './containers/App';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
import RTL from 'util/RTL';

export const store = configureStore();
export const queryClient = new QueryClient();
moment.locale('pt-br');

const MainApp = () =>
  <ErrorBoundary FallbackComponent={ErrorView}>
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <RTL>
              <App/>
            </RTL>
          </ConnectedRouter>
        </Provider>
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  </ErrorBoundary>;

export default MainApp;
