import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import Page403 from 'app/routes/Page403';
import { PERM_AUTHORITIES } from 'util/MobipixConstants';

const ProtectedRoute = ({ allowedRoleAuthorities, requiredPermissionAuthority, ...routeProps }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const renderRoute = () => {
    for (const allowedRoleAuthority of allowedRoleAuthorities) {
      if (authUser?.roleAuthorities?.includes?.(allowedRoleAuthority) &&
         (requiredPermissionAuthority === undefined ||
          authUser?.permissionAuthorities?.includes?.(requiredPermissionAuthority) ||
          authUser?.permissionAuthorities?.includes?.(PERM_AUTHORITIES.PERM_BO_ADMIN) ||
          authUser?.permissionAuthorities?.includes?.(PERM_AUTHORITIES.PERM_BO_ADMIN_MASTER))) {
        return <Route {...routeProps} />;
      }
    }

    return <Route component={Page403} />;
  }
  
  return authUser ?
    renderRoute() :
    <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
      <CircularProgress />
    </div>;
}

export default ProtectedRoute;
