// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const SHOW_ERROR_MESSAGE = 'show_error_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGN_IN = 'sign_in';
export const SIGN_OUT = 'sign_out';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const GET_USERS = 'get_users';
export const CREATE_USER = 'create_user';
export const UPDATE_USER = 'update_user';
  
export const SHOW_RESET_USER_PASSWORD_DIALOG = 'show_reset_user_password_dialog';
export const HIDE_RESET_USER_PASSWORD_DIALOG = 'hide_reset_user_password_dialog';

export const SHOW_UPDATE_USER_FORM = 'show_update_user_form';
export const SHOW_CREATE_USER_FORM = 'show_create_user_form';
export const HIDE_UPDATE_USER_FORM = 'hide_update_user_form';
export const HIDE_CREATE_USER_FORM = 'hide_create_user_form';

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

export const GET_TRANSPORT_OPERATOR_SUMMARY = 'get_transport_operator_summary';
export const REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY = 'request_get_transport_operator_summary';
export const REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY_FAILURE = 'request_get_transport_operator_summary_failure';
export const GET_PAYMENT_GATEWAY_SUMMARY = 'get_payment_gateway_summary';

export const GET_SCHEDULED_TRIPS_TAKEN = 'request_get_scheduled_trips_taken';
export const REQUEST_GET_SCHEDULED_TRIPS_TAKEN = 'request_get_scheduled_trips_taken';
export const REQUEST_GET_SCHEDULED_TRIPS_TAKEN_FAILURE = 'request_get_scheduled_trips_taken_failure';

export const GET_TRIPS_PUNCTUALITY_START_INDEX = 'request_get_trips_punctuality_start_index';
export const REQUEST_GET_TRIPS_PUNCTUALITY_START_INDEX = 'request_get_trips_punctuality_start_index';
export const REQUEST_GET_TRIPS_PUNCTUALITY_START_INDEX_FAILURE = 'request_get_trips_punctuality_start_index_failure';

export const GET_TRIPS_PUNCTUALITY_END_INDEX = 'request_get_trips_punctuality_end_index';
export const REQUEST_GET_TRIPS_PUNCTUALITY_END_INDEX = 'request_get_trips_punctuality_end_index';
export const REQUEST_GET_TRIPS_PUNCTUALITY_END_INDEX_FAILURE = 'request_get_trips_punctuality_end_index_failure';

export const GET_TRIPS_PUNCTUALITY_INDEX = 'request_get_trips_punctuality_index';
export const REQUEST_GET_TRIPS_PUNCTUALITY_INDEX = 'request_get_trips_punctuality_index';
export const REQUEST_GET_TRIPS_PUNCTUALITY_INDEX_FAILURE = 'request_get_trips_punctuality_index_failure';

export const GET_EARLY_START_TRIPS = 'request_get_early_start_trips';
export const REQUEST_GET_EARLY_START_TRIPS = 'request_get_early_start_trips';
export const REQUEST_GET_EARLY_START_TRIPS_FAILURE = 'request_get_early_start_trips_failure';

export const GET_LATE_START_TRIPS = 'request_get_late_start_trips';
export const REQUEST_GET_LATE_START_TRIPS = 'request_get_late_start_trips';
export const REQUEST_GET_LATE_START_TRIPS_FAILURE = 'request_get_late_start_trips_failure';

export const GET_ON_TIME_START_TRIPS = 'request_get_on_time_start_trips';
export const REQUEST_GET_ON_TIME_START_TRIPS = 'request_get_on_time_start_trips';
export const REQUEST_GET_ON_TIME_START_TRIPS_FAILURE = 'request_get_on_time_start_trips_failure';

export const GET_EARLY_END_TRIPS = 'request_get_early_end_trips';
export const REQUEST_GET_EARLY_END_TRIPS = 'request_get_early_end_trips';
export const REQUEST_GET_EARLY_END_TRIPS_FAILURE = 'request_get_early_end_trips_failure';

export const GET_LATE_END_TRIPS = 'request_get_late_end_trips';
export const REQUEST_GET_LATE_END_TRIPS = 'request_get_late_end_trips';
export const REQUEST_GET_LATE_END_TRIPS_FAILURE = 'request_get_late_end_trips_failure';

export const GET_ON_TIME_END_TRIPS = 'request_get_on_time_end_trips';
export const REQUEST_GET_ON_TIME_END_TRIPS = 'request_get_on_time_end_trips';
export const REQUEST_GET_ON_TIME_END_TRIPS_FAILURE = 'request_get_on_time_end_trips_failure';

export const GET_ACTIVE_TRIPS = 'request_get_active_trips';
export const REQUEST_GET_ACTIVE_TRIPS = 'request_get_active_trips';
export const REQUEST_GET_ACTIVE_TRIPS_FAILURE = 'request_get_active_trips_failure';

export const GET_PENDING_TRIPS = 'request_get_pending_trips';
export const REQUEST_GET_PENDING_TRIPS = 'request_get_pending_trips';
export const REQUEST_GET_PENDING_TRIPS_FAILURE = 'request_get_pending_trips_failure';

export const GET_FINISHED_TRIPS = 'request_get_finished_trips';
export const REQUEST_GET_FINISHED_TRIPS = 'request_get_finished_trips';
export const REQUEST_GET_FINISHED_TRIPS_FAILURE = 'request_get_finished_trips_failure';

export const GET_ERROR_TRIPS = 'request_get_error_trips';
export const REQUEST_GET_ERROR_TRIPS = 'request_get_error_trips';
export const REQUEST_GET_ERROR_TRIPS_FAILURE = 'request_get_error_trips_failure';

export const GET_NOT_DONE_TRIPS = 'request_get_not_done_trips';
export const REQUEST_GET_NOT_DONE_TRIPS = 'request_get_not_done_trips';
export const REQUEST_GET_NOT_DONE_TRIPS_FAILURE = 'request_get_not_done_trips_failure';

export const GET_TRANSIT_AGENCY_SUMMARY = 'get_transit_agency_summary';
export const REQUEST_GET_TRANSIT_AGENCY_SUMMARY = 'request_get_transit_agency_summary';
export const REQUEST_GET_TRANSIT_AGENCY_SUMMARY_FAILURE = 'request_get_transit_agency_summary_failure';

export const RESET_MOBIPIX_PWD_RESULT = 'reset_mobipix_pwd_result';

export const GET_TRANSACTIONS = 'get_transactions';
export const VOID_TRANSACTION = 'void_transaction';
export const SHOW_VOID_TRANSACTION = 'show_void_transaction';
export const HIDE_VOID_TRANSACTION = 'hide_void_transaction';
export const REQUEST_GET_TRANSACTIONS = 'request_get_transactions';
export const REQUEST_GET_TRANSACTIONS_FAILURE = 'request_get_transactions_failure';

export const CREATE_WALLET_COLOR_PALETTE = 'create_wallet_color_palette';

export const GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE = 'get_transactions_count_by_pguser_type';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE = 'request_get_transactions_count_by_pguser_type';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_FAILURE = 'request_get_transactions_count_by_pguser_type_failure';

export const GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY = 'get_transactions_count_by_pguser_type_and_day';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY = 'request_get_transactions_count_by_pguser_type_and_day';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY_FAILURE = 'request_get_transactions_count_by_pguser_type_and_day_failure';

export const GET_TRANSACTIONS_COUNT_BY_ROUTES = 'get_transactions_count_by_routes';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES = 'request_get_transactions_count_by_routes';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES_FAILURE = 'request_get_transactions_count_by_routes_failure';

export const GET_TRANSACTIONS_COUNT_BY_VEHICLES = 'get_transactions_count_by_vehicles';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES = 'request_get_transactions_count_by_vehicles';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES_FAILURE = 'request_get_transactions_count_by_vehicles_failure';

export const GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND = 'get_transactions_count_by_time_inbound';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND = 'request_get_transactions_count_by_time_inbound';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND_FAILURE = 'request_get_transactions_count_by_time_inbound_failure';

export const GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND = 'get_transactions_count_by_time_outbound';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND = 'request_get_transactions_count_by_time_outbound';
export const REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND_FAILURE = 'request_get_transactions_count_by_time_outbound_failure';

export const GET_PAYMENT_STATS = 'get_payment_stats';
export const REQUEST_GET_PAYMENT_STATS = 'request_get_payment_stats';
export const REQUEST_GET_PAYMENT_STATS_FAILURE = 'request_get_payment_stats_failure';

export const GET_VEHICLES = 'get_vehicles';
export const CREATE_VEHICLE = 'create_vehicle';
export const UPDATE_VEHICLE = 'update_vehicle';
export const DELETE_VEHICLE = 'delete_vehicle';
export const SHOW_UPDATE_VEHICLE_FORM = 'show_update_vehicle_form';
export const SHOW_CREATE_VEHICLE_FORM = 'show_create_vehicle_form';
export const SHOW_DELETE_VEHICLE_DIALOG = 'show_delete_vehicle_dialog';
export const HIDE_UPDATE_VEHICLE_FORM = 'hide_update_vehicle_form';
export const HIDE_CREATE_VEHICLE_FORM = 'hide_create_vehicle_form';
export const HIDE_DELETE_VEHICLE_DIALOG = 'hide_delete_vehicle_dialog';

export const CREATE_ONBOARD_DEVICE = 'create_onboard_device';
export const UPDATE_ONBOARD_DEVICE = 'update_onboard_device';
export const SHOW_UPDATE_ONBOARD_DEVICE_FORM = 'show_update_onboard_device_form';
export const SHOW_CREATE_ONBOARD_DEVICE_FORM = 'show_create_onboard_device_form';
export const HIDE_UPDATE_ONBOARD_DEVICE_FORM = 'hide_update_onboard_device_form';
export const HIDE_CREATE_ONBOARD_DEVICE_FORM = 'hide_create_onboard_device_form';

export const UPDATE_VEHICLE_TABLE_STATE = 'update_vehicle_table_state';

export const GET_QRCODE = 'get_qrcode';
export const HIDE_QRCODE = 'hide_qrcode';

export const GET_ENROLLMENTS = 'get_enrollments';

export const GET_FARE_PRICES = 'get_fare_prices';

export const GET_CONSUMPTION_BY_TRIP_REPORT = 'get_consumption_by_trip_report';

export const GET_CONSUMPTION_BY_ROUTE_REPORT = 'get_consumption_by_route_report';

export const GET_CREWS = 'get_crews';
export const CREATE_CREW = 'create_crew';
export const UPDATE_CREW = 'update_crew';
export const DELETE_CREW = 'delete_crew';
export const GET_CREW_QRCODE = 'get_crew_qrcode';
export const SHOW_UPDATE_CREW_FORM = 'show_update_crew_form';
export const SHOW_CREATE_CREW_FORM = 'show_create_crew_form';
export const SHOW_DELETE_CREW_DIALOG = 'show_delete_crew_dialog';
export const SHOW_CREW_QRCODE = 'show_crew_qrcode';
export const HIDE_UPDATE_CREW_FORM = 'hide_update_crew_form';
export const HIDE_CREATE_CREW_FORM = 'hide_create_crew_form';
export const HIDE_DELETE_CREW_DIALOG = 'hide_delete_crew_dialog';
export const HIDE_CREW_QRCODE = 'hide_crew_qrcode';

export const GET_ROUTES = 'get_routes';
export const CREATE_ROUTE = 'create_route';
export const UPDATE_ROUTE = 'update_route';
export const SHOW_UPDATE_ROUTE_FORM = 'show_update_route_form';
export const SHOW_CREATE_ROUTE_FORM = 'show_create_route_form';
export const HIDE_UPDATE_ROUTE_FORM = 'hide_update_route_form';
export const HIDE_CREATE_ROUTE_FORM = 'hide_create_route_form';

export const GET_ROUTE_GROUPS = 'get_route_groups';
export const CREATE_ROUTE_GROUPS = 'create_route_groups';
export const SHOW_CREATE_ROUTE_GROUPS_FORM = 'show_create_route_groups_form';
export const HIDE_CREATE_ROUTE_GROUPS_FORM = 'hide_create_route_groups_form';
export const UPDATE_ROUTE_GROUPS = 'update_route_groups';
export const SHOW_UPDATE_ROUTE_GROUPS_FORM = 'show_update_route_groups_form';
export const HIDE_UPDATE_ROUTE_GROUPS_FORM = 'hide_update_route_groups_form';
export const DELETE_ROUTE_GROUPS = 'delete_route_groups';
export const SHOW_DELETE_ROUTE_GROUPS_FORM = 'show_delete_route_groups_form';
export const HIDE_DELETE_ROUTE_GROUPS_FORM = 'hide_delete_route_groups_form';

export const GET_TRIPS = 'get_trips';
export const CREATE_TRIP = 'create_trip';
export const UPDATE_TRIP = 'update_trip';
export const DELETE_TRIP = 'delete_trip';
export const FINALIZE_TRIP = 'finalize_trip';
export const START_TRIP = 'start_trip';
export const DUPLICATE_TRIPS = 'duplicate_trips';
export const SHOW_UPDATE_TRIP_FORM = 'show_update_trip_form';
export const SHOW_DELETE_TRIP_DIALOG = 'show_delete_trip_dialog';
export const SHOW_FINALIZE_TRIP_FORM = 'show_finalize_trip_form';
export const SHOW_START_TRIP_FORM = 'show_start_trip_form';
export const SHOW_CREATE_TRIP_FORM = 'show_create_trip_form';
export const SHOW_DUPLICATE_TRIPS_FORM = 'show_duplicate_trips_form';
export const HIDE_UPDATE_TRIP_FORM = 'hide_update_trip_form';
export const HIDE_DELETE_TRIP_DIALOG = 'hide_delete_trip_dialog';
export const HIDE_FINALIZE_TRIP_FORM = 'hide_finalize_trip_form';
export const HIDE_START_TRIP_FORM = 'hide_start_trip_form';
export const HIDE_CREATE_TRIP_FORM = 'hide_create_trip_form';
export const HIDE_DUPLICATE_TRIPS_FORM = 'hide_duplicate_trips_form';

export const GET_ROUTES_AUTOCOMPLETE_OPTIONS = 'get_routes_autocomplete_options';
export const GET_FARE_TABLES_AUTOCOMPLETE_OPTIONS = 'get_fare_tables_autocomplete_options';
export const GET_DRIVERS_AUTOCOMPLETE_OPTIONS = 'get_drivers_autocomplete_options';
export const GET_VEHICLES_AUTOCOMPLETE_OPTIONS = 'get_vehicles_autocomplete_options';
export const GET_PAYMENT_GATEWAYS_AUTOCOMPLETE_OPTIONS = 'get_payment_gateways_autocomplete_options';
export const GET_TRANSIT_OPERATORS_AUTOCOMPLETE_OPTIONS = 'get_transit_operators_autocomplete_options';
export const GET_TRANSIT_AGENCIES_AUTOCOMPLETE_OPTIONS = 'get_transit_agencies_autocomplete_options';

export const GET_PIX_WEBHOOK = 'get_pix_webhook';
export const REQUEST_GET_PIX_WEBHOOK = 'request_get_pix_webhook';
export const REQUEST_GET_PIX_WEBHOOK_FAILURE = 'request_get_pix_webhook_failure';
