import {

  SIGN_OUT,

  GET_CREWS,
  CREATE_CREW,
  UPDATE_CREW,
  DELETE_CREW,
  GET_CREW_QRCODE,

  SHOW_UPDATE_CREW_FORM,
  SHOW_CREATE_CREW_FORM,
  SHOW_DELETE_CREW_DIALOG,
  SHOW_CREW_QRCODE,
  HIDE_UPDATE_CREW_FORM,
  HIDE_CREATE_CREW_FORM,
  HIDE_DELETE_CREW_DIALOG,
  HIDE_CREW_QRCODE,

} from "constants/ActionTypes";

const INIT_STATE = {

  crews: { data: [], count: 0 },
  crewUpdating: {
    isUpdatingACrew: false,
    currentUpdatingCrew: null
  },
  crewCreation: {
    isCreatingACrew: false,
  },
  crewDeletion: {
    isDeletingACrew: false,
    currentDeletingCrew: null
  },
  crewQRCode: {
    currentQRCodeTransitOperatorName: null,
    currentQRCodeCrewId: null,
    currentQRCodeCrewLabel: null,
    currentQRCodeCrewEnrollmentId: null,
    currentQRCodeData: null,
    isShowingQRCode: false,
  }

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_CREWS: { //action.payload == todas as entidades
      return {
        ...state,
        crews: {
          ...state.crews,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    }

    case CREATE_CREW: { //action.payload == entidade criada
      const newCrew = action.payload;

      return {
        ...state,
        crews: {
          ...state.crews,
          data: [
            newCrew,
          ...state.crews.data
          ],
          count: state.crews.count + 1,
        }
      };
    }

    case UPDATE_CREW: { //action.payload == entidade atualizada
      const updatedCrew = action.payload;

      const crewIndex = state.crews.data.findIndex(crew => crew.uuid == updatedCrew.uuid);

      const newCrews = state.crews.data.map((crew, index) => {
        if (index == crewIndex) {
          return updatedCrew;
        }
        return crew;
      });

      return {
        ...state,
        crews: {
          ...state.crews,
          data: newCrews,
        },
      };
    }

    case DELETE_CREW: { //action.payload == id da entidade deletada
      const crewId = action.payload;

      const newCrews = state.crews.data.filter(crew => crew.uuid !== crewId);

      return {
        ...state,
        crews: {
          ...state.crews,
          data: newCrews,
          count: state.crews.count - 1,
        }
      };
    }

    case GET_CREW_QRCODE: {
      const { qrCodePayload, transitOperatorName } = action.payload;

      return {
        ...state,
        crewQRCode: {
          ...state.crewQRCode,
          currentQRCodeData: qrCodePayload,
          currentQRCodeTransitOperatorName: transitOperatorName
        }
      }
    }

    case SHOW_UPDATE_CREW_FORM: { //action.payload == id da que começou a ser alterada
      const crewId = action.payload;
      const currentUpdatingCrew = state.crews.data.find(crew => crew.uuid == crewId);

      return {
        ...state,
        crewUpdating: {
          isUpdatingACrew: true,
          currentUpdatingCrew: currentUpdatingCrew
        },
      };
    }

    case SHOW_CREATE_CREW_FORM: {
      return {
        ...state,
        crewCreation: {
          isCreatingACrew: true,
        },
      };
    }

    case SHOW_DELETE_CREW_DIALOG: { //action.payload == id da que começou a ser deletada
      const crewId = action.payload;
      const currentDeletingCrew = state.crews.data.find(crew => crew.uuid == crewId);

      return {
        ...state,
        crewDeletion: {
          isDeletingACrew: true,
          currentDeletingCrew: currentDeletingCrew
        },
      };
    }

    case SHOW_CREW_QRCODE: {
      const { crewId, crewLabel, crewEnrollmentId } = action.payload;
      return {
        ...state,
        crewQRCode: {
          ...state.crewQRCode,
          currentQRCodeCrewId: crewId,
          currentQRCodeCrewLabel: crewLabel,
          currentQRCodeCrewEnrollmentId: crewEnrollmentId,
          isShowingQRCode: true
        }
      };
    }

    case HIDE_UPDATE_CREW_FORM: {
      return {
        ...state,
        crewUpdating: {
          isUpdatingACrew: false,
          currentUpdatingCrew: null
        },
      };
    }

    case HIDE_CREATE_CREW_FORM: {
      return {
        ...state,
        crewCreation: {
          isCreatingACrew: false,
        },
      };
    }

    case HIDE_DELETE_CREW_DIALOG: {
      return {
        ...state,
        crewDeletion: {
          isDeletingACrew: false,
          currentDeletingCrew: null
        },
      };
    }

    case HIDE_CREW_QRCODE: {
      return {
        ...state,
        crewQRCode: {
          ...state.crewQRCode,
          currentQRCodeCrewId: null,
          currentQRCodeCrewLabel: null,
          currentQRCodeCrewEnrollmentId: null,
          currentQRCodeData: null,
          currentQRCodeTransitOperatorName: null,
          isShowingQRCode: false
        }
      };
    }

    default: {
      return state;
    }

  }
}
