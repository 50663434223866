import {
  SIGN_OUT,
  GET_TRIPS,
  CREATE_TRIP,
  UPDATE_TRIP,
  DELETE_TRIP,
  FINALIZE_TRIP,
  START_TRIP,
  DUPLICATE_TRIPS,
  SHOW_UPDATE_TRIP_FORM,
  SHOW_DELETE_TRIP_DIALOG,
  SHOW_FINALIZE_TRIP_FORM,
  SHOW_START_TRIP_FORM,
  SHOW_CREATE_TRIP_FORM,
  SHOW_DUPLICATE_TRIPS_FORM,
  HIDE_UPDATE_TRIP_FORM,
  HIDE_DELETE_TRIP_DIALOG,
  HIDE_FINALIZE_TRIP_FORM,
  HIDE_START_TRIP_FORM,
  HIDE_CREATE_TRIP_FORM,
  HIDE_DUPLICATE_TRIPS_FORM,
} from 'constants/ActionTypes';

const INIT_STATE = {
  trips: {
    data: [],
    count: 0
  },
  tripUpdating: {
    isUpdatingAnTrip: false,
    currentUpdatingTrip: null
  },
  tripCreation: {
    isCreatingAnTrip: false,
  },
  tripDeletion: {
    isDeletingATrip: false,
    currentDeletingTrip: null,
  },
  tripFinalization: {
    isFinalizingATrip: false,
    currentFinalizingTrip: null,
  },
  tripStart: {
    isStartingATrip: false,
    currentStartingTrip: null,
  },
  tripsDuplication: {
    isDuplicatingTrips: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_TRIPS: {
      return {
        ...state,
        trips: action.payload,
      };
    }

    case CREATE_TRIP: {
      const newTrip = action.payload;

      return {
        ...state,
        trips: {
          ...state.trips,
          data: [
            newTrip,
            ...state.trips.data
          ],
          count: state.trips.count + 1
        }
      };
    }

    case UPDATE_TRIP: {
      const updatedTrip = action.payload;

      const tripIndex = state.trips.data.findIndex(trip => trip.uuid == updatedTrip.uuid);

      const newTrips = state.trips.data.map((trip, index) => {
        if (index == tripIndex) {
          return updatedTrip;
        }
        return trip;
      });

      return {
        ...state,
        trips: {
          ...state.trips,
          data: newTrips,
        }
      };
    }

    case DELETE_TRIP: {
      const tripId = action.payload;
      const newTrips = state.trips.data.filter(trip => trip.uuid !== tripId);

      return {
        ...state,
        trips: {
          ...state.trips,
          data: newTrips,
          count: state.trips.count -1,
        }
      };
    }

    case FINALIZE_TRIP: {
      const finalizedTrip = action.payload;

      const newTrips = state.trips.data.map(trip => {
        if (trip.uuid === finalizedTrip.uuid) {
          return finalizedTrip;
        }
        return trip;
      });

      return {
        ...state,
        trips: {
          ...state.trips,
          data: newTrips,
        }
      };
    }

    case START_TRIP: {
      const startedTrip = action.payload;

      const newTrips = state.trips.data.map(trip => {
        if (trip.uuid === startedTrip.uuid) return startedTrip;
        return trip;
      });

      return {
        ...state,
        trips: {
          ...state.trips,
          data: newTrips,
        }
      };
    }

    case DUPLICATE_TRIPS: {
      const duplicatedTrips = action.payload;

      return {
        ...state,
        trips: {
          ...state.trips,
          data: [...duplicatedTrips, ...state.trips.data],
          count: duplicatedTrips.length + state.trips.count,
        }
      };
    }

    case SHOW_UPDATE_TRIP_FORM: {
      const tripId = action.payload;
      const currentUpdatingTrip = state.trips.data.find(trip => trip.uuid == tripId);

      return {
        ...state,
        tripUpdating: {
          isUpdatingAnTrip: true,
          currentUpdatingTrip: currentUpdatingTrip
        },
      };
    }

    case SHOW_DELETE_TRIP_DIALOG: {
      const tripId = action.payload;
      const currentDeletingTrip = state.trips.data.find(trip => trip.uuid === tripId);

      return {
        ...state,
        tripDeletion: {
          isDeletingATrip: true,
          currentDeletingTrip: currentDeletingTrip,
        },
      };
    }

    case SHOW_FINALIZE_TRIP_FORM: {
      const tripId = action.payload;
      const currentFinalizingTrip = state.trips.data.find(trip => trip.uuid === tripId);

      return {
        ...state,
        tripFinalization: {
          isFinalizingATrip: true,
          currentFinalizingTrip: currentFinalizingTrip
        },
      };
    }

    case SHOW_START_TRIP_FORM: {
      const tripId = action.payload;
      const currentStartingTrip = state.trips.data.find(trip => trip.uuid === tripId);

      return {
        ...state,
        tripStart: {
          isStartingATrip: true,
          currentStartingTrip: currentStartingTrip,
        },
      };
    }

    case SHOW_CREATE_TRIP_FORM: {
      return {
        ...state,
        tripCreation: {
          isCreatingAnTrip: true,
        },
      };
    }

    case SHOW_DUPLICATE_TRIPS_FORM: {
      return {
        ...state,
        tripsDuplication: {
          ...state.tripsDuplication,
          isDuplicatingTrips: true,
        }
      };
    }

    case HIDE_UPDATE_TRIP_FORM: {
      return {
        ...state,
        tripUpdating: {
          isUpdatingAnTrip: false,
          currentUpdatingTrip: null
        },
      };
    }

    case HIDE_DELETE_TRIP_DIALOG: {
      return {
        ...state,
        tripDeletion: {
          isDeletingAnTrip: false,
          currentDeletingTrip: null
        },
      };
    }

    case HIDE_FINALIZE_TRIP_FORM: {
      return {
        ...state,
        tripFinalization: {
          isFinalizingATrip: false,
          currentFinalizingTrip: null
        },
      };
    }

    case HIDE_START_TRIP_FORM: {
      return {
        ...state,
        tripStart: {
          isStartingATrip: false,
          currentStartingTrip: null,
        },
      };
    }

    case HIDE_CREATE_TRIP_FORM: {
      return {
        ...state,
        tripCreation: {
          isCreatingAnTrip: false,
        },
      };
    }

    case HIDE_DUPLICATE_TRIPS_FORM: {
      return {
        ...state,
        tripsDuplication: {
          ...state.tripsDuplication,
          isDuplicatingTrips: false,
        }
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
}
