import {

    SIGN_OUT,

    GET_FARE_PRICES,

} from "../constants/ActionTypes";

const INIT_STATE = {

  fare_prices: [],

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_FARE_PRICES: {
      return {
        ...state,
        fare_prices: action.payload,
      };
    }

    default:
      return state;

    }
}
