import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PAYMENT_GATEWAY_SUMMARY,
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';

export const getPaymentGatewaySummary = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get('dashboard/payment-gateway/summary')
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PAYMENT_GATEWAY_SUMMARY, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
      });
  };
};
