import React, { lazy, Suspense } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from 'util/ProtectedRoute';
import ErrorView from 'util/ErrorView';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ROLE_AUTHORITIES, PERM_AUTHORITIES } from 'util/MobipixConstants';

const setItemWithExpiration = (key, value, ttl) => {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiration = key => {
  const itemString = localStorage.getItem(key);

  if (!itemString) {
    return null;
  }

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

const importComponent = componentLoaderFunction => new Promise(
  async (resolve, reject) => {
    try {
      resolve(await componentLoaderFunction());
    } catch (error) {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      if (error?.message && chunkFailedMessage.test(error.message)) {
        if (getItemWithExpiration('chunk_failed') === null) {
          setItemWithExpiration('chunk_failed', 'true', 10000);
          window.location.reload();
        }
      }

      reject(error);
    }
  }
);

const buildLazyComponent = componentLoaderFunction => withErrorBoundary(
  lazy(() => importComponent(componentLoaderFunction)),
  { FallbackComponent: ErrorView }
);

const Home = buildLazyComponent(() => import('./Home'));
const TransportOperatorTransactionsDashboard = buildLazyComponent(() => import('./TransportOperatorTransactionsDashboard'));
const TransportOperatorRealTimeDashboard = buildLazyComponent(() => import('./TransportOperatorRealTimeDashboard'));
const VehiclesDashboard = buildLazyComponent(() => import('./VehiclesDashboard'));
const TripsDashboard = buildLazyComponent(() => import('./TripsDashboard'));
const PaymentGatewayDashboard = buildLazyComponent(() => import('./PaymentGatewayDashboard'));
const TransitAgencyTransactionsDashboard = buildLazyComponent(() => import('./TransitAgencyTransactionsDashboard'));
const TransitAgencyEnrollmentsDashboard = buildLazyComponent(() => import('./TransitAgencyEnrollmentsDashboard'));
const TransitAgencyConsumptionDashboard = buildLazyComponent(() => import('./TransitAgencyConsumptionDashboard'));
const Transactions = buildLazyComponent(() => import('./Transactions'));
const Vehicles = buildLazyComponent(() => import('./Vehicles'));
const FarePrices = buildLazyComponent(() => import('./FarePrices'));
const RoutesPage = buildLazyComponent(() => import('./Routes'));
const RouteGroups = buildLazyComponent(() => import('./RouteGroups'));
const Enrollments = buildLazyComponent(() => import('./Enrollments'));
const ConsumptionReport = buildLazyComponent(() => import('./ConsumptionReport'));
const ConsumptionByTripReport = buildLazyComponent(() => import('./ConsumptionByTripReport'));
const ConsumptionByRouteReport = buildLazyComponent(() => import('./ConsumptionByRouteReport'));
const TurnstileTurnReport = buildLazyComponent(() => import('./TurnstileTurnReport'));
const TemporalIntegrations = buildLazyComponent(() => import('./TemporalIntegrations'));
const Crews = buildLazyComponent(() => import('./Crews'));
const Users = buildLazyComponent(() => import('./Users'));
const Trips = buildLazyComponent(() => import('./Trips'));
const CrewRoadmap = buildLazyComponent(() => import('./CrewRoadmap'));
const Exports = buildLazyComponent(() => import('./Exports'));
const Page404 = buildLazyComponent(() => import('./Page404'));

const Routes = () => (
  <Suspense
    fallback={
      <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
        <CircularProgress/>
      </div>
    }
  >
    <Switch>
      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER, ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER, ROLE_AUTHORITIES.ROLE_PAYMENT_GATEWAY_USER]}
        path="/home"
        component={Home}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_TO_DASHBOARD_TRANSACTIONS}
        path="/transport-operator-transactions-dashboard"
        component={TransportOperatorTransactionsDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_DASHBOARD_REAL_TIME}
        path="/transport-operator-real-time-dashboard"
        component={TransportOperatorRealTimeDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_DASHBOARD_VEHICLES}
        path="/vehicles-dashboard"
        component={VehiclesDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_DASHBOARD_TRIPS}
        path="/trips-dashboard"
        component={TripsDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PAYMENT_GATEWAY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_PGU_DASHBOARD}
        path="/payment-gateway-dashboard"
        component={PaymentGatewayDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_TA_DASHBOARD_TRANSACTIONS}
        path="/transit-agency-transactions-dashboard"
        component={TransitAgencyTransactionsDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_DASHBOARD_ENROLLMENTS}
        path="/transit-agency-enrollments-dashboard"
        component={TransitAgencyEnrollmentsDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_DASHBOARD_CONSUMPTION}
        path="/transit-agency-consumption-dashboard"
        component={TransitAgencyConsumptionDashboard}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER, ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER, ROLE_AUTHORITIES.ROLE_PAYMENT_GATEWAY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_TRANSACTIONS}
        path="/transactions"
        component={Transactions}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REGISTER_VEHICLES}
        path="/vehicles"
        component={Vehicles}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REPORT_FARE_PERIODS}
        path="/fare-periods"
        component={FarePrices}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER, ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REGISTER_ROUTES}
        path="/routes"
        component={RoutesPage}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER ]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_CONFIG_TEMP_INTEGRATIONS}
        path='/route-group'
        component={RouteGroups}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REPORT_ENROLLMENTS}
        path="/enrollments/:item?"
        component={Enrollments}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REPORT_DAILY_CONSUMPTION}
        path="/consumption-report"
        component={ConsumptionReport}
        exact
      />
      
      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REPORT_TRIP_CONSUMPTION}
        path="/consumption-by-trip-report"
        component={ConsumptionByTripReport}
        exact
      />
      
      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REPORT_ROUTE_CONSUMPTION}
        path="/consumption-by-route-report"
        component={ConsumptionByRouteReport}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REPORT_TURNSTILE}
        path="/giro-de-catraca"
        component={TurnstileTurnReport}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_CONFIG_TEMP_INTEGRATIONS}
        path="/temporal-integrations"
        component={TemporalIntegrations}
        exact
      />


      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REGISTER_CREWS}
        path="/crews"
        component={Crews}
        exact
      />


      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PAYMENT_GATEWAY_USER, ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER, ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REGISTER_USERS}
        path="/users"
        component={Users}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REGISTER_TRIPS}
        path="/trips"
        component={Trips}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_REPORT_ROADMAP}
        path="/crew-roadmap"
        component={CrewRoadmap}
        exact
      />

      <ProtectedRoute
        allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER]}
        requiredPermissionAuthority={PERM_AUTHORITIES.PERM_EXPORT_DATA}
        path="/exportacoes"
        component={Exports}
        exact
      />

      <Route component={Page404}/>
    </Switch>
  </Suspense>
);

export default Routes;
