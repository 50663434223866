import {
  GET_ROUTE_GROUPS,
  CREATE_ROUTE_GROUPS,
  UPDATE_ROUTE_GROUPS,
  DELETE_ROUTE_GROUPS,

  SHOW_CREATE_ROUTE_GROUPS_FORM,
  HIDE_CREATE_ROUTE_GROUPS_FORM,
  SHOW_UPDATE_ROUTE_GROUPS_FORM,
  HIDE_UPDATE_ROUTE_GROUPS_FORM,
  SHOW_DELETE_ROUTE_GROUPS_FORM,
  HIDE_DELETE_ROUTE_GROUPS_FORM,

  SIGN_OUT,
} from "constants/ActionTypes";

const INIT_STATE = {
  group: {
    data: [],
    count: 0,
  },
  create: {
    isCreating: false,
  },
  update: {
    isUpdating: false,
    currentUpdating: null,
  },
  delete: {
    isDeleting: false,
    currentDeleting: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }
    
    case GET_ROUTE_GROUPS: {
      return {
        ...state,
        group: action.payload,
      }
    }

    case CREATE_ROUTE_GROUPS: {
      const newRouteGroup = action.payload;

      return {
        ...state,
        group: {
          ...state.group,
          data: [
            newRouteGroup,
            ...state.group.data,
          ],
          count: state.group.count + 1,
        }
      }
    }

    case UPDATE_ROUTE_GROUPS: {
      const updatedGroup = action.payload;

      return {
        ...state,
        group: {
          ...state.group,
          data: state.group.data.map(
            group => group?.id === updatedGroup?.id ? updatedGroup : group
          ),
        }
      }
    }

    case DELETE_ROUTE_GROUPS: {
      const deletedGroupId =  action.payload;

      return {
        ...state,
        group: {
          ...state.group,
          data: state.group.data.filter(
            group => group?.id !== deletedGroupId
          ),
          count: state.group.count - 1,
        }
      }
    }

    case SHOW_CREATE_ROUTE_GROUPS_FORM: {
      return {
        ...state,
        create: {
          ...state.create,
          isCreating: true,
        }
      }
    }

    case HIDE_CREATE_ROUTE_GROUPS_FORM: {
      return {
        ...state,
        create: {
          ...state.create,
          isCreating: false,
        }
      }
    }

    case SHOW_UPDATE_ROUTE_GROUPS_FORM: {
      const currentUpdatingId = action.payload;

      return {
        ...state,
        update: {
          ...state.update,
          isUpdating: true,
          currentUpdating: state.group.data.find(
            group => group?.id === currentUpdatingId
          ),
        },
      }
    }

    case HIDE_UPDATE_ROUTE_GROUPS_FORM: {
      return {
        ...state,
        update: {
          ...state.update,
          isUpdating: false,
          currentUpdating: null,
        }
      }
    }

    case SHOW_DELETE_ROUTE_GROUPS_FORM: {
      const currentDeletedId = action.payload;

      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleting: true,
          currentDeleting: state.group.data.find(
            group => group?.id === currentDeletedId
          )
        }
      }
    }

    case HIDE_DELETE_ROUTE_GROUPS_FORM: {
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleting: false,
          currentDeleting: null,
        }
      }
    }

    default: {
      return state;
    }
  }
}