import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import darkThemeTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ResetMobipixPassword from 'app/routes/open/ResetMobipixPassword';
import AppLayout from './AppLayout';
import FreshChat from 'react-freshchat';
import { ToastContainer } from 'react-toastify';
import { TableCell } from '@material-ui/core';

const theme = createTheme(darkThemeTheme);

// FIX https://github.com/gregnb/mui-datatables/issues/1893
const oldRender = TableCell.render;
TableCell.render = function(...args) {
  const [props, ...otherArgs] = args;
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props;
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
  } else {
    return oldRender.apply(this, args);
  }
};

const App = () => {
  const { isLoggedIn, authUser } = useSelector(({ auth }) => auth);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={AppLocale['pt'].locale} messages={AppLocale['pt'].messages}>
        <div className="app-main">
          <Switch>
            <Route exact path="/signin" component={SignIn}/>
            <Route exact path="/signup" component={SignUp}/>
            <Route exact path="/account/reset/finish" component={ResetMobipixPassword}/>
            <Route exact path="/">
              {isLoggedIn ? <Redirect to="/home"/> : <Redirect to="/signin"/>}
            </Route>
            <Route path="/*">
              {isLoggedIn ? <AppLayout/> : <Redirect to="/signin"/>}
            </Route>
          </Switch>

          <ToastContainer/>

          {
            isLoggedIn &&
            <FreshChat
              token="531424f4-45dd-4992-9e75-eb00481c8dbe"
              email={authUser ? authUser.email : ''}
              firstName={authUser ? authUser.firstName : ''}
              lastName=""
              externalId={authUser ? authUser.login : ''}
              siteId="MobiPix Backoffice"
              onInit={widget => {
                widget.user.setProperties({
                  email: authUser ? authUser.email : '',
                  firstName: authUser ? authUser.firstName : '',
                  externalId: authUser ? authUser.login : '',
                  paymentArrangementId: authUser ? authUser.paymentArrangementId : '',
                  paymentArrangementMnemonic: authUser ? authUser.paymentArrangementMnemonic : '',
                  paymentArrangementGuiBrCode: authUser ? authUser.paymentArrangementGuiBrCode : '',
                  companiesName: authUser && authUser.companiesName ? JSON.stringify(authUser.companiesName) : '',
                  companies: authUser && authUser.companies ? JSON.stringify(authUser.companies) : '',
                  authorities: authUser && authUser.authorities ? JSON.stringify(authUser.authorities) : '',
                  isCompanyUser: authUser ? authUser.isCompanyUser : '',
                  isPaymentArrangementUser: authUser ? authUser.isPaymentArrangementUser : '',
                  activated: authUser ? authUser.activated : '',
                });
              }}
            />
          }
        </div>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
