import {
    SIGN_OUT,
    GET_CONSUMPTION_BY_ROUTE_REPORT,
  } from "../constants/ActionTypes";
    
  const INIT_STATE = {  
    consumptionByRouteReportData: [],
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case SIGN_OUT: {
        return INIT_STATE;
      }
  
      case GET_CONSUMPTION_BY_ROUTE_REPORT: {
  
        if (!action.payload) {
          return INIT_STATE;
        }
  
        return {
          ...state,
          consumptionByRouteReportData: action.payload,
        };
      }
  
      default:
        return state;
  
    }
  }
  