import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => {
  return {
    titleIcon: {
      backgroundColor: '#180605',
      color: '#ff9891',
      '&:hover': {
        backgroundColor: '#180605',
        cursor: 'default',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '8rem',
      },
    },
  };
});

const ErrorView = ({ error }) => {
  const classes = useStyles();

  return (
    <div style={{ width: '100%', height: '100%', color: '#ff9891' }} className="d-flex align-items-center justify-content-center font-weight-bold flex-column">
      <IconButton aria-label="delete" disableRipple className={classes.titleIcon}>
        <ErrorOutlineIcon />
      </IconButton>
      <div style={{ fontSize: '1.25rem' }} className="mt-3 text-center">
        Oops! Um erro inesperado ocorreu: {error.message}
      </div>
      <div style={{ fontSize: '1.25rem' }}>
        Tente novamente mais tarde
      </div>
    </div>
  )
}

export default ErrorView;
