import {
  SIGN_OUT,
  GET_CONSUMPTION_BY_TRIP_REPORT,
} from "../constants/ActionTypes";
  
const INIT_STATE = {  
  consumptionByTripReportData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_CONSUMPTION_BY_TRIP_REPORT: {

      if (!action.payload) {
        return INIT_STATE;
      }

      return {
        ...state,
        consumptionByTripReportData: action.payload,
      };
    }

    default:
      return state;

  }
}
