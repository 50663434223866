import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CREATE_WALLET_COLOR_PALETTE,
  GET_TRANSACTIONS,
  REQUEST_GET_TRANSACTIONS,
  REQUEST_GET_TRANSACTIONS_FAILURE,
  VOID_TRANSACTION,
  SHOW_VOID_TRANSACTION,
  HIDE_VOID_TRANSACTION,
  GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_ROUTES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_VEHICLES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND_FAILURE,
  GET_PAYMENT_STATS,
  REQUEST_GET_PAYMENT_STATS,
  REQUEST_GET_PAYMENT_STATS_FAILURE
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import { toast } from 'react-toastify';
import MobipixUtils from 'util/MobipixUtils';

export const getTransactions = (page, size, sort, filter) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTIONS });
    mobipixApi.get(`transactions?page=${page}&size=${size}&sort=${sort}&${filter}`)
      .then((response) => {
        let data = response.data;
        let totalCount = response.headers['x-total-count'] ? parseInt(response.headers['x-total-count'], 10) : 0;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTIONS, payload: { data: data, count: totalCount } });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar passagens');
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSACTIONS_FAILURE });
      });
  };
};

export const voidTransaction = transactionUuid => {
  return dispatch => {
    dispatch({ type: FETCH_START});
    mobipixApi.post(`transactions/${transactionUuid}/void`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: VOID_TRANSACTION, payload: data });
        toast.success('Passagem extornada com sucesso!')
        dispatch({ type: HIDE_VOID_TRANSACTION });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao extornar a passagem')
        dispatch({ type: FETCH_ERROR });
      })
  }
}

export const showVoidTransaction = transactionUuid => ({ type: SHOW_VOID_TRANSACTION, payload: transactionUuid });

export const hideVoidTransaction = () => ({ type: HIDE_VOID_TRANSACTION });

export const getTransactionsCountAndAmountByPgUserType = ({
                                                            startDatetime,
                                                            endDatetime,
                                                            transportOperatorIdsArray,
                                                            transportOperatorNamesArray,
                                                            vehicleIds,
                                                            routeIds,
                                                          } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const transportOperatorIdsFilterQueryParam = MobipixUtils.getTransportOperatorIdsFilterQueryParam(transportOperatorIdsArray);
  const transportOperatorNamesFilterQueryParam = MobipixUtils.getTransportOperatorNamesFilterQueryParam(transportOperatorNamesArray);
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE });
    mobipixApi.get(`dashboard/transactions/count-and-amount-by-pguser-type?${startDatetimeStr}${endDatetimeStr}${transportOperatorIdsFilterQueryParam}${transportOperatorNamesFilterQueryParam}${vehicleFilter}${routeFilter}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE, payload: data });
        dispatch({ type: CREATE_WALLET_COLOR_PALETTE, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_FAILURE });
      });
  };
};

export const getTransactionsCountByPgUserTypeAndDay = ({
                                                         startDatetime,
                                                         endDatetime,
                                                         transportOperatorIdsArray,
                                                         transportOperatorNamesArray,
                                                         vehicleIds,
                                                         routeIds
                                                       } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const transportOperatorIdsFilterQueryParam = MobipixUtils.getTransportOperatorIdsFilterQueryParam(transportOperatorIdsArray);
  const transportOperatorNamesFilterQueryParam = MobipixUtils.getTransportOperatorNamesFilterQueryParam(transportOperatorNamesArray);
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY });
    mobipixApi.get(`dashboard/transactions/count-by-pguser-type-and-day?${startDatetimeStr}${endDatetimeStr}${transportOperatorIdsFilterQueryParam}${transportOperatorNamesFilterQueryParam}${vehicleFilter}${routeFilter}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY_FAILURE });
      });
  };
};

export const getTransactionsCountByRoutes = ({ startDatetime, endDatetime, vehicleIds, routeIds } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES });
    mobipixApi.get(`dashboard/transport-operator/count-transactions-by-routes?${startDatetimeStr}${endDatetimeStr}${vehicleFilter}${routeFilter}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTIONS_COUNT_BY_ROUTES, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES_FAILURE });
      });
  };
};

export const getTransactionsCountByVehicles = ({ startDatetime, endDatetime, vehicleIds, routeIds } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES });
    mobipixApi.get(`dashboard/transport-operator/count-transactions-by-vehicles?${startDatetimeStr}${endDatetimeStr}${vehicleFilter}${routeFilter}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTIONS_COUNT_BY_VEHICLES, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES_FAILURE });
      });
  };
};

export const getTransactionsCountByTimeInbound = ({
                                                    startDatetime,
                                                    endDatetime,
                                                    clientTimezone,
                                                    vehicleIds,
                                                    routeIds,
                                                  } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND });
    mobipixApi.get(`dashboard/transport-operator/count-transactions-by-time?${startDatetimeStr}${endDatetimeStr}${vehicleFilter}${routeFilter}&clientTimezone=${encodeURIComponent(clientTimezone)}&tripDirection=INBOUND`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND_FAILURE });
      });
  };
};

export const getTransactionsCountByTimeOutbound = ({
                                                     startDatetime,
                                                     endDatetime,
                                                     clientTimezone,
                                                     vehicleIds,
                                                     routeIds,
                                                   } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND });
    mobipixApi.get(`dashboard/transport-operator/count-transactions-by-time?${startDatetimeStr}${endDatetimeStr}${vehicleFilter}${routeFilter}&clientTimezone=${encodeURIComponent(clientTimezone)}&tripDirection=OUTBOUND`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND_FAILURE });
      });
  };
};

export const getTransportOperatorPaymentStats = ({ startDatetime, endDatetime, vehicleIds, routeIds } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_PAYMENT_STATS });
    mobipixApi.get(`dashboard/transport-operator/payment-stats?${startDatetimeStr}${endDatetimeStr}${vehicleFilter}${routeFilter}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PAYMENT_STATS, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_PAYMENT_STATS_FAILURE });
      });
  };
};

export const getTransitAgencyPaymentStats = (startDatetime, endDatetime, transportOperatorIdsArray, transportOperatorNamesArray) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const transportOperatorIdsFilterQueryParam = MobipixUtils.getTransportOperatorIdsFilterQueryParam(transportOperatorIdsArray);
  const transportOperatorNamesFilterQueryParam = MobipixUtils.getTransportOperatorNamesFilterQueryParam(transportOperatorNamesArray);

  return async dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_PAYMENT_STATS });
    try {
      const res = await mobipixApi.get(`dashboard/transit-agency/payment-stats?${startDatetimeStr}${endDatetimeStr}${transportOperatorIdsFilterQueryParam}${transportOperatorNamesFilterQueryParam}`);
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: GET_PAYMENT_STATS, payload: res.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: FETCH_ERROR });
      dispatch({ type: REQUEST_GET_PAYMENT_STATS_FAILURE });
    }
  };
};
