import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_FARE_PRICES,
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import { toast } from 'react-toastify';

export const getFarePrices = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get('fare-periods')
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_FARE_PRICES, payload: data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar matrizes tarifárias');
        dispatch({ type: FETCH_ERROR });
      });
  };
};
