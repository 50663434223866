import lime from '@material-ui/core/colors/lime';

export default {
  palette: {
    type: 'dark',
    types: {
      dark: {
        background: {
          paper: '#2f3437',
          default: '#2f3437',
          appBar: '#2f3437',
          contentFrame: '#2f3437',
          chip: '#2f3437',
          avatar: '#2f3437'
        }
      }
    },
    primary: {
      light: lime[400],
      main: lime[700],
      dark: lime[900],
      contrastText: '#fff'
    },
    secondary: {
      light: lime[400],
      main: lime[600],
      dark: lime[900],
      contrastText: '#fff'
    },
    background: {
      paper: '#2f3437',
      default: '#2f3437',
      appBar: '#2f3437',
      contentFrame: '#2f3437',
      chip: '#2f3437',
      avatar: '#2f3437'
    }
  },
  status: {
    danger: 'orange',
  },

  typography: {
    button: {
      fontWeight: 400,
      textAlign:
        'capitalize'
    },
  },
};
