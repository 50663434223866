import {

  SIGN_OUT,

  GET_VEHICLES,
  CREATE_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,

  SHOW_UPDATE_VEHICLE_FORM,
  SHOW_CREATE_VEHICLE_FORM,
  SHOW_DELETE_VEHICLE_DIALOG,
  HIDE_UPDATE_VEHICLE_FORM,
  HIDE_CREATE_VEHICLE_FORM,
  HIDE_DELETE_VEHICLE_DIALOG,

  CREATE_ONBOARD_DEVICE,
  UPDATE_ONBOARD_DEVICE,

  SHOW_UPDATE_ONBOARD_DEVICE_FORM,
  SHOW_CREATE_ONBOARD_DEVICE_FORM,
  HIDE_UPDATE_ONBOARD_DEVICE_FORM,
  HIDE_CREATE_ONBOARD_DEVICE_FORM,

  UPDATE_VEHICLE_TABLE_STATE,

  GET_QRCODE,
  HIDE_QRCODE,

} from "../constants/ActionTypes";

const INIT_STATE = {
  vehicles: [],
  vehicleUpdating: {
    isUpdatingAVehicle: false,
    currentUpdatingVehicle: null
  },
  vehicleCreation: {
    isCreatingAVehicle: false,
  },
  vehicleDeletion: {
    isDeletingAVehicle: false,
    currentDeletingVehicle: null
  },
  onboardDeviceUpdating: {
    isUpdatingAnOnboardDevice: false,
    currentUpdatingOnboardDevice: null
  },
  onboardDeviceCreation: {
    isCreatingAnOnboardDevice: false,
    creatingOnboardDeviceVehicleId: null
  },
  vehicleTableState: {
    expandedRows: []
  },
  shownQRCode: '',
  shownQRCodeOnboardDeviceUuid: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_VEHICLES: {
      return {
        ...state,
        vehicles: action.payload,
      };
    }

    case CREATE_VEHICLE: {
      const newVehicle = action.payload;

      return {
        ...state,
        vehicles: [
          newVehicle,
          ...state.vehicles
        ],
        vehicleTableState: {
          expandedRows: state.vehicleTableState.expandedRows.map(expandedRow => ++expandedRow)
        },
      };
    }

    case UPDATE_VEHICLE: {
      const updatedVehicle = action.payload;

      const vehicleIndex = state.vehicles.findIndex(vehicle => vehicle.id == updatedVehicle.id);

      const newVehicles = state.vehicles.map((vehicle, index) => {
        if (index == vehicleIndex) {
          return updatedVehicle;
        }
        return vehicle;
      });

      return {
        ...state,
        vehicles: newVehicles,
      };
    }

    case DELETE_VEHICLE: {
      const vehicleId = action.payload;

      const newVehicles = state.vehicles.filter(vehicle => vehicle.id !== vehicleId);

      return {
        ...state,
        vehicles: newVehicles,
      };
    }

    case SHOW_UPDATE_VEHICLE_FORM: {
      const vehicleId = action.payload;
      const currentUpdatingVehicle = state.vehicles.find(vehicle => vehicle.id == vehicleId);

      return {
        ...state,
        vehicleUpdating: {
          isUpdatingAVehicle: true,
          currentUpdatingVehicle: currentUpdatingVehicle
        },
      };
    }

    case SHOW_CREATE_VEHICLE_FORM: {
      return {
        ...state,
        vehicleCreation: {
          isCreatingAVehicle: true,
        },
      };
    }

    case SHOW_DELETE_VEHICLE_DIALOG: {
      const vehicleId = action.payload;
      const currentDeletingVehicle = state.vehicles.find(vehicle => vehicle.id == vehicleId);

      return {
        ...state,
        vehicleDeletion: {
          isDeletingAVehicle: true,
          currentDeletingVehicle: currentDeletingVehicle
        },
      };
    }

    case HIDE_UPDATE_VEHICLE_FORM: {
      return {
        ...state,
        vehicleUpdating: {
          isUpdatingAVehicle: false,
          currentUpdatingVehicle: null
        },
      };
    }

    case HIDE_CREATE_VEHICLE_FORM: {
      return {
        ...state,
        vehicleCreation: {
          isCreatingAVehicle: false,
        },
      };
    }

    case HIDE_DELETE_VEHICLE_DIALOG: {
      return {
        ...state,
        vehicleDeletion: {
          isDeletingAVehicle: false,
          currentDeletingVehicle: null
        },
      };
    }

    case CREATE_ONBOARD_DEVICE: {
      const newOnboardDevice = action.payload;
      const newVehicles = state.vehicles.map(vehicle => {
        if (vehicle.id == newOnboardDevice.vehicleId) {
          return {
            ...vehicle,
            onboardDeviceList: [
              newOnboardDevice,
              ...vehicle.onboardDeviceList
            ]
          }
        } else {
          return vehicle;
        }
      });

      return {
        ...state,
        vehicles: newVehicles
      };
    }

    case UPDATE_ONBOARD_DEVICE: {
      const updatedOnboardDevice = action.payload;

      const newVehicles = state.vehicles.map(vehicle => {
        if (vehicle.id == updatedOnboardDevice.vehicleId) {
          return {
            ...vehicle,
            onboardDeviceList: vehicle.onboardDeviceList.map(onboardDevice => {
              if (onboardDevice.uuid == updatedOnboardDevice.uuid) {
                return updatedOnboardDevice;
              } else {
                return onboardDevice;
              }
            })
          };
        } else {
          return vehicle;
        }
      });

      return {
        ...state,
        vehicles: newVehicles,
      };
    }

    case SHOW_UPDATE_ONBOARD_DEVICE_FORM: {
      const { onboardDeviceId, vehicleId } = action.payload;
      const currentUpdatingOnboardDevice = state.vehicles.find(vehicle => vehicle.id == vehicleId).onboardDeviceList.find(onboardDevice => onboardDevice.uuid == onboardDeviceId);

      return {
        ...state,
        onboardDeviceUpdating: {
          isUpdatingAnOnboardDevice: true,
          currentUpdatingOnboardDevice: currentUpdatingOnboardDevice
        },
      };
    }

    case SHOW_CREATE_ONBOARD_DEVICE_FORM: {
      return {
        ...state,
        onboardDeviceCreation: {
          isCreatingAnOnboardDevice: true,
          creatingOnboardDeviceVehicleId: action.payload
        },
      };
    }

    case HIDE_UPDATE_ONBOARD_DEVICE_FORM: {
      return {
        ...state,
        onboardDeviceUpdating: {
          isUpdatingAnOnboardDevice: false,
          currentUpdatingOnboardDevice: null
        },
      };
    }

    case HIDE_CREATE_ONBOARD_DEVICE_FORM: {
      return {
        ...state,
        onboardDeviceCreation: {
          isCreatingAnOnboardDevice: false,
          creatingOnboardDeviceVehicleId: null
        },
      };
    }

    case UPDATE_VEHICLE_TABLE_STATE: {
      return {
        ...state,
        vehicleTableState: {
          expandedRows: action.payload
        }
      };
    }

    case GET_QRCODE: {
      return {
        ...state,
        shownQRCode: action.payload.qrCodeData,
        shownQRCodeOnboardDeviceUuid: action.payload.shownQRCodeOnboardDeviceUuid,
      };
    }

    case HIDE_QRCODE: {
      return {
        ...state,
        shownQRCode: '',
        shownQRCodeOnboardDeviceUuid: '',
      };
    }

    default: {
      return state;
    }
  }
}
