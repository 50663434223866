import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ROUTES_AUTOCOMPLETE_OPTIONS,
  GET_FARE_TABLES_AUTOCOMPLETE_OPTIONS,
  GET_DRIVERS_AUTOCOMPLETE_OPTIONS,
  GET_VEHICLES_AUTOCOMPLETE_OPTIONS,
  GET_PAYMENT_GATEWAYS_AUTOCOMPLETE_OPTIONS,
  GET_TRANSIT_OPERATORS_AUTOCOMPLETE_OPTIONS,
  GET_TRANSIT_AGENCIES_AUTOCOMPLETE_OPTIONS,
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

export const getRoutesAutocompleteOptions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`routes/for-selection`)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_ROUTES_AUTOCOMPLETE_OPTIONS, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar opções de linhas');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getFareTablesAutocompleteOptions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`fare-tables/for-selection`)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_FARE_TABLES_AUTOCOMPLETE_OPTIONS, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar opções de matrizes tarifárias');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getDriversAutocompleteOptions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`crews/for-selection`)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_DRIVERS_AUTOCOMPLETE_OPTIONS, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar opções de motoristas/cobradores');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const useGetDriversAutocompleteOptions = () =>
  useQuery(
    'walletConfigAutocompleteOptions',
    () => mobipixApi.get(`crews/for-selection`).then(res => res.data),
    { onError: console.log },
  );

export const getVehiclesAutocompleteOptions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`vehicles/for-selection`)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_VEHICLES_AUTOCOMPLETE_OPTIONS, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar opções de veículos');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getPaymentGatewaysAutocompleteOptions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`paymentGatewayAccounts/for-selection`)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PAYMENT_GATEWAYS_AUTOCOMPLETE_OPTIONS, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar opções de arranjos');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getTransitOperatorsAutocompleteOptions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`transit-operators/for-selection`)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSIT_OPERATORS_AUTOCOMPLETE_OPTIONS, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar opções de operadores');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getTransitAgenciesAutocompleteOptions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`transitAgencies/for-selection`)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSIT_AGENCIES_AUTOCOMPLETE_OPTIONS, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar opções de agências');
        dispatch({ type: FETCH_ERROR });
      });
  };
};


