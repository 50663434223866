export const ENABLED = 'ENABLED';
export const DISABLED = 'DISABLED';
export const TAG_MASK = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '-', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
export const EMAIL_REGEX_VALIDATION = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const TRANSACTION_STATUS = [
  'CREATED',
  'AUTHORIZED',
  'UNAUTHORIZED',
  'FAILED',
  'CANCELED',
  'UNKNOWN_ERROR',
  'ROLLBACKED',
  'FULL_REFUND',
  'PARTIAL_REFUND',
];

export const FARE_TIME_BASED_INTEGRATION_STATUS = ['CREATED', 'IN_PROGRESS', 'COMPLETED'];

export const PAYMENT_GATEWAY_USER_TYPE = [
  'REGULAR',
  'WORKER',
  'STUDENT',
  'STUDENT_FREE',
  'ELDERLY',
  'SPECIAL',
  'SINGLE_PASS',
  'GENERIC_1',
  'GENERIC_2',
  'GENERIC_3',
  'GENERIC_4',
  'GENERIC_5',
  'GENERIC_6',
  'GENERIC_7',
  'GENERIC_8',
  'GENERIC_9',
  'GENERIC_10',
];

export const COLOR_PALETTE = [
 '#007690',
 '#00C9E2',
 '#009973',
 '#5EC283',
 '#AFB42B',
 '#FAF373',
 '#FACB73',
 '#FF8F00',
 '#CD5624',
 '#EC766A',
 '#FF4B5D',
 '#D55091',
 '#BC96E6',
 '#9E75CC',
 '#8A5293',
 '#6F4B7C',
 '#9F0062',
];

export const TRIP_SYNCHRONIZATION_STATUS = {
  TRIP_NOT_ENDED: 'TRIP_NOT_ENDED',
  SYNCHRONIZED: 'SYNCHRONIZED',
  NOT_SYNCHRONIZED: 'NOT_SYNCHRONIZED',
  ONBOARD_DEVICE_ERROR: 'ONBOARD_DEVICE_ERROR',
};

export const CREW_TYPE = {
  CREW: 'CREW',
  SUPERVISOR: 'SUPERVISOR',
};

export const ROLE_AUTHORITIES = {
  ROLE_PAYMENT_GATEWAY_USER: 'ROLE_PAYMENT_GATEWAY_USER',
  ROLE_TRANSIT_OPERATOR_USER: 'ROLE_TRANSIT_OPERATOR_USER',
  ROLE_TRANSIT_AGENCY_USER: 'ROLE_TRANSIT_AGENCY_USER',
};

export const PERM_AUTHORITIES = {
  PERM_BO_ADMIN_MASTER: "PERM_BO_ADMIN_MASTER",
  PERM_BO_ADMIN: "PERM_BO_ADMIN",
  PERM_TO_DASHBOARD_TRANSACTIONS: "PERM_TO_DASHBOARD_TRANSACTIONS",
  PERM_DASHBOARD_REAL_TIME: 'PERM_DASHBOARD_REAL_TIME',
  PERM_DASHBOARD_VEHICLES: 'PERM_DASHBOARD_VEHICLES',
  PERM_DASHBOARD_TRIPS: "PERM_DASHBOARD_TRIPS",
  PERM_PGU_DASHBOARD: 'PERM_PGU_DASHBOARD',
  PERM_TA_DASHBOARD_TRANSACTIONS: 'PERM_TA_DASHBOARD_TRANSACTIONS',
  PERM_DASHBOARD_ENROLLMENTS: 'PERM_DASHBOARD_ENROLLMENTS',
  PERM_DASHBOARD_CONSUMPTION: 'PERM_DASHBOARD_CONSUMPTION',
  PERM_TRANSACTIONS: 'PERM_TRANSACTIONS',
  PERM_REGISTER_VEHICLES: 'PERM_REGISTER_VEHICLES',
  PERM_REPORT_FARE_PERIODS: 'PERM_REPORT_FARE_PERIODS',
  PERM_REGISTER_ROUTES: 'PERM_REGISTER_ROUTES',
  PERM_REPORT_ENROLLMENTS: 'PERM_REPORT_ENROLLMENTS',
  PERM_REPORT_DAILY_CONSUMPTION: 'PERM_REPORT_DAILY_CONSUMPTION',
  PERM_REPORT_TRIP_CONSUMPTION: 'PERM_REPORT_TRIP_CONSUMPTION',
  PERM_REPORT_ROUTE_CONSUMPTION: 'PERM_REPORT_ROUTE_CONSUMPTION',
  PERM_REPORT_TURNSTILE: 'PERM_REPORT_TURNSTILE',
  PERM_CONFIG_TEMP_INTEGRATIONS: 'PERM_CONFIG_TEMP_INTEGRATIONS',
  PERM_REGISTER_CREWS: 'PERM_REGISTER_CREWS',
  PERM_REGISTER_USERS: 'PERM_REGISTER_USERS',
  PERM_REGISTER_TRIPS: 'PERM_REGISTER_TRIPS',
  PERM_REPORT_ROADMAP: 'PERM_REPORT_ROADMAP',
  PERM_EXPORT_DATA: 'PERM_EXPORT_DATA',
  PERM_TRANSACTION_VOID: 'PERM_TRANSACTION_VOID',
};

export const paymentGatewayUserPermissionAuthorities = {
  'Dashboard': [
    { value: PERM_AUTHORITIES.PERM_PGU_DASHBOARD, label: 'Dashboard' },
  ],
  // 'Cadastros': [
  //   { value: PERM_AUTHORITIES.PERM_REGISTER_USERS, label: 'Usuários' },
  // ],
  'Movimentações financeiras': [
    { value: PERM_AUTHORITIES.PERM_TRANSACTIONS, label: 'Passagens' },
  ],
};

export const transitAgencyUserPermissionAuthorities = {
  'Dashboard': [
    { value: PERM_AUTHORITIES.PERM_TA_DASHBOARD_TRANSACTIONS, label: 'Transações' },
    { value: PERM_AUTHORITIES.PERM_DASHBOARD_ENROLLMENTS, label: 'Cadastros' },
    { value: PERM_AUTHORITIES.PERM_DASHBOARD_CONSUMPTION, label: 'Consumo' },
  ],
  'Cadastros': [
    { value: PERM_AUTHORITIES.PERM_REGISTER_ROUTES, label: 'Linhas' },
    // { value: PERM_AUTHORITIES.PERM_REGISTER_USERS, label: 'Usuários' },
  ],
  'Movimentações financeiras': [
    { value: PERM_AUTHORITIES.PERM_TRANSACTIONS, label: 'Passagens' },
  ],
  'Relatórios': [
    { value: PERM_AUTHORITIES.PERM_REPORT_FARE_PERIODS, label: 'Matriz Tarifária' },
  ],
  'Configurações': [
    { value: PERM_AUTHORITIES.PERM_CONFIG_TEMP_INTEGRATIONS, label: 'Integração Temporal' },
  ],
};

export const transitOperatorUserPermissionAuthorities = {
  'Dashboard': [
    { value: PERM_AUTHORITIES.PERM_TO_DASHBOARD_TRANSACTIONS, label: 'Transações' },
    { value: PERM_AUTHORITIES.PERM_DASHBOARD_REAL_TIME, label: 'Tempo Real' },
    { value: PERM_AUTHORITIES.PERM_DASHBOARD_VEHICLES, label: 'Veículos' },
    { value: PERM_AUTHORITIES.PERM_DASHBOARD_TRIPS, label: 'Viagens' },
  ],
  'Cadastros': [
    { value: PERM_AUTHORITIES.PERM_REGISTER_CREWS, label: 'Funcionários' },
    // { value: PERM_AUTHORITIES.PERM_REGISTER_USERS, label: 'Usuários' },
    { value: PERM_AUTHORITIES.PERM_REGISTER_VEHICLES, label: 'Veículos' },
    { value: PERM_AUTHORITIES.PERM_REGISTER_ROUTES, label: 'Linhas' },
    { value: PERM_AUTHORITIES.PERM_REGISTER_TRIPS, label: 'Viagens' },
  ],
  'Movimentações financeiras': [
    { value: PERM_AUTHORITIES.PERM_TRANSACTIONS, label: 'Passagens' },
  ],
  'Relatórios': [
    { value: PERM_AUTHORITIES.PERM_REPORT_DAILY_CONSUMPTION, label: 'Consumo Diários' },
    { value: PERM_AUTHORITIES.PERM_REPORT_TRIP_CONSUMPTION, label: 'Relatório de Passageiros por Viagens' },
    { value: PERM_AUTHORITIES.PERM_REPORT_ROUTE_CONSUMPTION, label: 'Relatório de Passagens por Linha' },
    { value: PERM_AUTHORITIES.PERM_REPORT_ENROLLMENTS, label: 'Análise de Cadastros' },
    { value: PERM_AUTHORITIES.PERM_REPORT_TURNSTILE, label: 'Giro de Catraca' },
    { value: PERM_AUTHORITIES.PERM_REPORT_ROADMAP, label: 'Roteiro' },
    { value: PERM_AUTHORITIES.PERM_EXPORT_DATA, label: 'Exportações' },
  ],
};
