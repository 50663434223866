import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { userSignIn } from 'actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { loading } = useSelector(({ common }) => common);

  const doLogin = (e) => {
    e.preventDefault();
    dispatch(userSignIn({email, password}));
  }

  if (authUser) return <Redirect path="/home"/>;

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content" style={{backgroundColor: '#434f5a'}}>

        <div className="app-logo-content d-flex align-items-center justify-content-center"
              style={{backgroundColor: '#afb42b'}}>
          <Link className="logo-lg" to="/" title="MobiPix">
            <img src={require("assets/images/mobipix_white_logo.png")}
              width="100%"
              alt="MobiPix" title="MobiPix" />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1><IntlMessages id="appModule.welcome" /></h1>
          </div>


          <div className="app-login-form">
            <form onSubmit={(e) => { doLogin(e) }}>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.login"/>}
                  fullWidth
                  onChange={(event) => { setEmail(event.target.value) }}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  required
                  oninvalid="Campo obrigatório"
                />
                
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) => { setPassword(event.target.value) }}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  required
                  oninvalid="Campo obrigatório"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button type="submit"
                    variant="contained" color="primary" 
                  >
                    <IntlMessages id="appModule.signIn"/>
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>

      {
        loading &&
        <div className="loader-view">
          <CircularProgress />
        </div>
      }
    </div>
  );
};

export default SignIn;
