import React, { useState, useEffect } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import { useSelector } from 'react-redux';
import { PERM_AUTHORITIES } from 'util/MobipixConstants';

const SideBarContent = () => {
  const [navigationMenus, setNavigationMenus] = useState([]);
  const authUser = useSelector(({ auth }) => auth.authUser);
  const permissionAuthorities = authUser?.permissionAuthorities ?? [];

  const isRouteAvailable = permissionAuthority =>
  permissionAuthorities.includes(PERM_AUTHORITIES.PERM_BO_ADMIN_MASTER) ||
    permissionAuthorities.includes(PERM_AUTHORITIES.PERM_BO_ADMIN) ||
    permissionAuthorities.includes(permissionAuthority);

  const fillNavigationMenus = () => {
    const newNavigationMenus = [
      {
        name: 'dashboard',
        type: 'section',
        children: []
      },
      {
        name: 'cadastros',
        type: 'section',
        children: []
      },
      {
        name: 'movimentações financeiras',
        type: 'section',
        children: []
      },
      {
        name: 'relatórios',
        type: 'section',
        children: []
      },
      {
        name: 'configurações',
        type: 'section',
        children: []
      },
    ];

    if (authUser.isTransportOperatorUser) {
      if (isRouteAvailable(PERM_AUTHORITIES.PERM_TO_DASHBOARD_TRANSACTIONS)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Transações',
          link: '/transport-operator-transactions-dashboard',
          icon: 'chart',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_DASHBOARD_REAL_TIME)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Tempo Real',
          link: '/transport-operator-real-time-dashboard',
          icon: 'chart-donut',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_DASHBOARD_VEHICLES)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Veículos',
          link: '/vehicles-dashboard',
          icon: 'bus',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_DASHBOARD_TRIPS)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Viagens',
          link: '/trips-dashboard',
          icon: 'navigation',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_CREWS)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Funcionários',
          link: '/crews',
          icon: 'pin-account',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_USERS)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Usuários',
          link: '/users',
          icon: 'account',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_TRANSACTIONS)) {
        newNavigationMenus[2].children.push({
          type: 'item',
          name: 'pages.transactions',
          link: '/transactions',
          icon: 'money',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_VEHICLES)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'pages.vehicles',
          link: '/vehicles',
          icon: 'bus',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REPORT_DAILY_CONSUMPTION)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Consumo Diário',
          link: '/consumption-report',
          icon: 'chart-donut',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REPORT_TRIP_CONSUMPTION)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Relatório de Passageiros por Viagens',
          link: '/consumption-by-trip-report',
          icon: 'receipt',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REPORT_ROUTE_CONSUMPTION)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Relatório de Passagens por Linha',
          link: '/consumption-by-route-report',
          icon: 'receipt',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REPORT_ENROLLMENTS)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Análise de Cadastros',
          link: '/enrollments',
          icon: 'info',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REPORT_TURNSTILE)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Giro de catraca',
          link: '/giro-de-catraca',
          icon: 'repeat',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_ROUTES)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Linhas',
          link: '/routes',
          icon: 'directions',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_TRIPS)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Viagens',
          link: '/trips',
          icon: 'navigation',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REPORT_ROADMAP)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Roteiro',
          link: '/crew-roadmap',
          icon: 'swap-alt',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_EXPORT_DATA)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Exportações',
          link: '/exportacoes',
          icon: 'download'
        });
      }
    }

    if (authUser.isPaymentGatewayUser) {
      if (isRouteAvailable(PERM_AUTHORITIES.PERM_PGU_DASHBOARD)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'pages.payment.gateway.dashboard',
          link: '/payment-gateway-dashboard',
          icon: 'chart',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_USERS)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Usuários',
          link: '/users',
          icon: 'account',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_TRANSACTIONS)) {
        newNavigationMenus[2].children.push({
          type: 'item',
          name: 'pages.transactions',
          link: '/transactions',
          icon: 'money',
        });
      }
    }

    if (authUser.isTransitAgencyUser) {
      if (isRouteAvailable(PERM_AUTHORITIES.PERM_TA_DASHBOARD_TRANSACTIONS)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Transações',
          link: '/transit-agency-transactions-dashboard',
          icon: 'chart',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_DASHBOARD_ENROLLMENTS)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Cadastros',
          link: '/transit-agency-enrollments-dashboard',
          icon: 'chart-donut',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_DASHBOARD_CONSUMPTION)) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Consumo',
          link: '/transit-agency-consumption-dashboard',
          icon: 'receipt',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_TRANSACTIONS)) {
        newNavigationMenus[2].children.push({
          type: 'item',
          name: 'pages.transactions',
          link: '/transactions',
          icon: 'money',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_CONFIG_TEMP_INTEGRATIONS)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'pages.route.groups',
          link: '/route-group',
          icon: 'labels',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_ROUTES)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Linhas', //TODO (caio:20201223) - pages.linhas
          link: '/routes',
          icon: 'directions',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REGISTER_USERS)) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Usuários',
          link: '/users',
          icon: 'account',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_REPORT_FARE_PERIODS)) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'pages.fare.prices',
          link: '/fare-periods',
          icon: 'local-atm',
        });
      }

      if (isRouteAvailable(PERM_AUTHORITIES.PERM_CONFIG_TEMP_INTEGRATIONS)) {
        newNavigationMenus[4].children.push({
          type: 'item',
          name: 'Integração Temporal',
          link: '/temporal-integrations',
          icon: 'time',
        });
      }
    }

    const filteredNewNavigationMenus = newNavigationMenus.filter(navigationSection => navigationSection.children.length !== 0);

    setNavigationMenus(
      filteredNewNavigationMenus.length !== 0 ?
        filteredNewNavigationMenus :
        [{ name: 'Nenhuma página disponível', type: 'section' }]
    );

  }

  useEffect(() => {
    if (authUser) {
      fillNavigationMenus();
    }
  }, [authUser]);

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
