import {
  SIGN_OUT,
  GET_TRANSIT_AGENCY_SUMMARY,
  REQUEST_GET_TRANSIT_AGENCY_SUMMARY,
  REQUEST_GET_TRANSIT_AGENCY_SUMMARY_FAILURE
} from "../constants/ActionTypes";

const INIT_STATE = {
  dashboard: {
    transitAgencySummary: {
      transitAgencyName: '',
      authorizedGetOnsTotal: 0,
      authorizedGetOnsTotalAmount: 0,
      linesTotal: 0,
      vehiclesTotal: 0,
      onboardDevicesTotal: 0,
      tripsTotal: 0,
      operatorsTotal: 0
    },
    loadingTransitAgencySummary: false,
    loadingTransitAgencySummaryError: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_TRANSIT_AGENCY_SUMMARY: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          transitAgencySummary: action.payload,
          loadingTransitAgencySummary: false,
          loadingTransitAgencySummaryError: false
        },
      };
    }

    case REQUEST_GET_TRANSIT_AGENCY_SUMMARY: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loadingTransitAgencySummary: true,
          loadingTransitAgencySummaryError: false
        }
      }
    }

    case REQUEST_GET_TRANSIT_AGENCY_SUMMARY_FAILURE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loadingTransitAgencySummary: false,
          loadingTransitAgencySummaryError: true
        }
      }
    }

    default: {
      return state;
    }
  }
}
