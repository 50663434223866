import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SIGN_IN,
  SIGN_OUT,
  RESET_MOBIPIX_PWD_RESULT,
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import { toast } from 'react-toastify';
import formatErrorResponse from 'util/MobipixErrorsUtils';
import { queryClient } from 'MainApp';

export const userSignIn = ({ email, password }) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const { data: { id_token: token } } = await mobipixApi.post('auth', {
        username: email,
        password: password,
        rememberMe: false,
      });
      const { data: authUser } = await mobipixApi.get('account', { headers: { Authorization: `Bearer ${token}` } });

      authUser.isTransportOperatorUser = false;
      authUser.isPaymentGatewayUser = false;
      authUser.isTransitAgencyUser = false;

      for (const authority of authUser.authorities) {
        if (authority === 'ROLE_TRANSIT_OPERATOR_USER')
          authUser.isTransportOperatorUser = true;
        if (authority === 'ROLE_PAYMENT_GATEWAY_USER')
          authUser.isPaymentGatewayUser = true;
        if (authority === 'ROLE_TRANSIT_AGENCY_USER')
          authUser.isTransitAgencyUser = true;
      }

      if (!authUser.isTransportOperatorUser && !authUser.isPaymentGatewayUser && !authUser.isTransitAgencyUser) {
        dispatch({ type: FETCH_ERROR });
        toast.error('Usuário inválido');
      } else {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGN_IN, payload: { token, authUser, } });
      }
    } catch (error) {
      console.log(error);
      toast.error(formatErrorResponse(error));
      dispatch({ type: FETCH_ERROR });
    }
  };
};

export const signOut = () => {
  return async dispatch => {
    dispatch({ type: SIGN_OUT });
    queryClient.clear();

    if (window.fcWidget) {
      try {
        await window.fcWidget.user.clear();
        window.fcWidget.destroy();
      } catch (error) {
        window.fcWidget.destroy();
      }
    }
  };
};

export const userSignUp = ({ _name, _email, _password }) => {
  /* not required yet */
};

export const resetMobipixPasswordRequest = (email) => {
  const req = email;
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.post('account/reset-password/init', req)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(error => {
        console.log(error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const resetMobipixPassword = ({ resetKey, newPassword }) => {
  const req = {
    key: resetKey,
    newPassword: newPassword
  };
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.post('account/reset-password/finish', req)
      .then(() => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: RESET_MOBIPIX_PWD_RESULT, payload: true });
      })
      .catch(error => {
        console.log(error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: RESET_MOBIPIX_PWD_RESULT, payload: false });
      });
  };
};

export const clearResetMobipixPwdResult = () => {
  return dispatch => {
    dispatch({ type: RESET_MOBIPIX_PWD_RESULT, payload: false });
  };
};
