import {

  SIGN_OUT,

  GET_ENROLLMENTS,

} from "../constants/ActionTypes";

const INIT_STATE = {

  enrollments: {},

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_ENROLLMENTS: {
      return {
        ...state,
        enrollments: action.payload,
      };
    }

    default:
      return state;
  }
}
