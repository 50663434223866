import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CREWS,
  GET_CREW_QRCODE,
  CREATE_CREW,
  UPDATE_CREW,
  DELETE_CREW,
  SHOW_UPDATE_CREW_FORM,
  SHOW_CREATE_CREW_FORM,
  SHOW_DELETE_CREW_DIALOG,
  SHOW_CREW_QRCODE,
  HIDE_UPDATE_CREW_FORM,
  HIDE_CREATE_CREW_FORM,
  HIDE_DELETE_CREW_DIALOG,
  HIDE_CREW_QRCODE,
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import { toast } from 'react-toastify';

const crewBaseEndpoint = 'crews';

export const getCrews = (page, size, sort, filter) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`${crewBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`)
      .then(res => {
        const totalCount = res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_CREWS, payload: { data: res.data, count: totalCount }});
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar funcionários');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const createCrew = (newCrew) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.post(crewBaseEndpoint, newCrew)
      .then(res => {
        toast.success('Funcionário adicionado');
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_CREW, payload: res.data });
        dispatch({ type: HIDE_CREATE_CREW_FORM });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao adicionnar funcionário');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const updateCrew = (updatedCrew) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.put(crewBaseEndpoint, updatedCrew)
      .then(res => {
        toast.success('Funcionário editado');
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_CREW, payload: res.data });
        dispatch({ type: HIDE_UPDATE_CREW_FORM });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao editar funcionário');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const deleteCrew = (crewId) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.delete(`${crewBaseEndpoint}/${crewId}`)
      .then(_res => {
        toast.success('Funcionário excluído');
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_CREW, payload: crewId });
        dispatch({ type: HIDE_DELETE_CREW_DIALOG });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao excluir funcionário');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getCrewQrcode = crewId => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`${crewBaseEndpoint}/${crewId}/qrcode`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_CREW_QRCODE, payload: data });
      }).catch(error => {
        console.log(error);
        toast.error('Erro ao gerar o QR Code do funcionário');
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: HIDE_CREW_QRCODE });
      });
  };
};

export const showUpdateCrewForm = (crewId) => {
  return {
    type: SHOW_UPDATE_CREW_FORM,
    payload: crewId,
  };
};

export const showCreateCrewForm = () => {
  return { type: SHOW_CREATE_CREW_FORM };
};

export const showDeleteCrewDialog = (crewId) => {
  return {
    type: SHOW_DELETE_CREW_DIALOG,
    payload: crewId,
  };
};

export const showCrewQrcode = (crewId, crewLabel, crewEnrollmentId) => {
  return {
    type: SHOW_CREW_QRCODE,
    payload: {
      crewId: crewId,
      crewLabel: crewLabel,
      crewEnrollmentId: crewEnrollmentId
    },
  };
};

export const hideUpdateCrewForm = () => {
  return { type: HIDE_UPDATE_CREW_FORM };
};

export const hideCreateCrewForm = () => {
  return { type: HIDE_CREATE_CREW_FORM };
};

export const hideDeleteCrewDialog = () => {
  return { type: HIDE_DELETE_CREW_DIALOG };
};

export const hideCrewQrcode = () => {
  return { type: HIDE_CREW_QRCODE };
};
