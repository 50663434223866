import {
  SIGN_OUT,
  GET_PIX_WEBHOOK,
  REQUEST_GET_PIX_WEBHOOK,
  REQUEST_GET_PIX_WEBHOOK_FAILURE,
} from "constants/ActionTypes";

const INIT_STATE = {
  isConnected: false,
  webhooks: [],
  loadingWebhookStatus: false,
  loadingWebhookStatusError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_PIX_WEBHOOK: {
      const webhooks = action.payload;
      let isConnected = true;

      if (webhooks.length > 0) {
        for (const account of webhooks) {
          if (account.webhookUrl.length === 0) {
            isConnected = false;
          }
        }
      } else {
        isConnected = false;
      }

      return {
        ...state,
        isConnected: isConnected,
        webhooks: webhooks,
        loadingWebhookStatus: false,
        loadingWebhookStatusError: false
      };
    }

    case REQUEST_GET_PIX_WEBHOOK: {
      return {
        ...state,
        loadingWebhookStatus: true,
        loadingWebhookStatusError: false,
      }
    }

    case REQUEST_GET_PIX_WEBHOOK_FAILURE: {
      return {
        ...state,
        loadingWebhookStatus: false,
        loadingWebhookStatusError: true,
      }
    }

    default: {
      return state;
    }
  }
}
