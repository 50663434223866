import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TRANSPORT_OPERATOR_SUMMARY,
  REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY,
  REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY_FAILURE
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import MobipixUtils from 'util/MobipixUtils';

export const getTransportOperatorSummary = ({ startDatetime, endDatetime, vehicleIds, routeIds } = {}) => {
  const startDatetimeStr = startDatetime ? `&startDatetime=${startDatetime}` : '';
  const endDatetimeStr = endDatetime ? `&endDatetime=${endDatetime}` : '';
  const vehicleFilter = MobipixUtils.getFilterQueryParam('vehicleIds', vehicleIds);
  const routeFilter = MobipixUtils.getFilterQueryParam('routeIds', routeIds);

  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY });
    mobipixApi.get(`dashboard/transport-operator/summary?${startDatetimeStr}${endDatetimeStr}${vehicleFilter}${routeFilter}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_TRANSPORT_OPERATOR_SUMMARY, payload: data });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_ERROR });
        dispatch({ type: REQUEST_GET_TRANSPORT_OPERATOR_SUMMARY_FAILURE });
      });
  };
};
