import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  resetMobipixPassword, 
  clearResetMobipixPwdResult, 
} from 'actions/Auth';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link, useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core';

const ResetMobipixPassword = (props) => {
  const [resetKey, setResetKey] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const dispatch = useDispatch();
  const { loading, message } = useSelector(({ common }) => common);
  const [showPassword, setShowPassword] = useState(false);
  const resetMobipixPwdResult = useSelector(({ auth }) => auth.resetMobipixPwdResult);
  const history = useHistory();

  const query = new URLSearchParams(props.location.search);

  useEffect(() => {
    const key = query.get('key');
    setResetKey(key);
  }, []);

  useEffect(() => {
    if (resetMobipixPwdResult) {
      dispatch(clearResetMobipixPwdResult());
      history.push('/');
    }
  }, [resetMobipixPwdResult]);

  const handleClickShowPassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else { setShowPassword(false); }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const doSubmit = (e) => {
    e.preventDefault();
    if (password && passwordConfirmation && password === passwordConfirmation) {
        dispatch(resetMobipixPassword({ resetKey, newPassword: password }));
    } else {
        window.alert("Passwords must be equal.");
    }
  }

  return (
  <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">

    <form onSubmit={(e) => { doSubmit(e) }}>
      <div style={{backgroundColor: '#3f51b5', padding: '10px', borderRadius: '20px', border: '50px #3f51b5'}}>
        <img src={require("assets/images/mobipix_white_logo.png")}
             width="100%"
             alt="Mobipix" title="Mobipix" />
      </div>
      <fieldset>
        <TextField
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            fullWidth
            onChange={(event) => { setPassword(event.target.value) }}
            defaultValue={password}
            margin="normal"
            className="mt-1 my-sm-3"
            required
            InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
        />

        <TextField
            type={'password'}
            label="New Password Confirmation"
            fullWidth
            onChange={(event) => { setPasswordConfirmation(event.target.value) }}
            defaultValue={passwordConfirmation}
            margin="normal"
            className="mt-1 my-sm-3"
            required
        />

        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button type="submit" variant="contained" color="primary">
            Confirm
          </Button>
        </div>

      </fieldset>
    </form>
    {
      loading &&
      <div className="loader-view">
        <CircularProgress />
      </div>
    }
  </div>
  );
}

export default ResetMobipixPassword;
