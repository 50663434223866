import currencyFormatter from 'currency-formatter';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import { intervalToDuration, formatDuration } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {
  GET_ACTIVE_TRIPS,
  GET_PENDING_TRIPS,
  GET_FINISHED_TRIPS,
  GET_ERROR_TRIPS,
  GET_NOT_DONE_TRIPS,
  REQUEST_GET_ACTIVE_TRIPS,
  REQUEST_GET_PENDING_TRIPS,
  REQUEST_GET_FINISHED_TRIPS,
  REQUEST_GET_ERROR_TRIPS,
  REQUEST_GET_NOT_DONE_TRIPS,
  REQUEST_GET_ACTIVE_TRIPS_FAILURE,
  REQUEST_GET_PENDING_TRIPS_FAILURE,
  REQUEST_GET_FINISHED_TRIPS_FAILURE,
  REQUEST_GET_ERROR_TRIPS_FAILURE,
  REQUEST_GET_NOT_DONE_TRIPS_FAILURE
} from 'constants/ActionTypes';
import { 
  CREW_TYPE, 
  ROLE_AUTHORITIES,
  paymentGatewayUserPermissionAuthorities,
  transitOperatorUserPermissionAuthorities,
  transitAgencyUserPermissionAuthorities,
 } from './MobipixConstants';

export default {

  convertCentsToRealAndCents(value) {
    return value / 100;
  },

  formatDecimal(value) {
    return currencyFormatter.format(value, {
      symbol: '',
      decimal: ',',
      thousand: '.',
      precision: 2,
      format: '%v', // %v is the value and %s is the symbol
    });
  },

  formatThousandSeparator(value) {
    if (!value) {
      return value;
    }
    return this.formatDecimal(value).split(',')[0];
  },

  formatDecimalInCents(valueInCents) {
    return "R$ " + this.formatDecimal(valueInCents / 100.00);
  },

  formatFloatInPercentage(value) {
    if(isNaN(value)){
      return " - "
    }
    return  Math.round(((value*100) + Number.EPSILON) * 100) / 100 + " %";
  },

  parseStringTwoDecimalsCurrencyToValueInCents(currencyFormattedStrValue) {
    if (!currencyFormattedStrValue) {
      return currencyFormattedStrValue;
    }
    let cleanStr = currencyFormattedStrValue.replace(/[,\\.]/g, '');
    let valueInCents = Number(cleanStr);
    return valueInCents;
  },

  formatDateDDMMYYYYHHMMSS(datetimeToFormat) {
    if (!datetimeToFormat) return datetimeToFormat;
    return moment(datetimeToFormat).utcOffset(-180).format("DD/MM/YYYY HH:mm:ss");
  },

  formatDateDDMMYYYY(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).utcOffset(-180).format("DD/MM/YYYY");
    return formatted;
  },

  formatDateDDMMYYYYHHMM(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).utcOffset(-180).format("DD/MM/YYYY HH:mm");
    return formatted;
  },

  formatDateDDMMHHMM(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).utcOffset(-180).format("DD/MM - HH:mm");
    return formatted;
  },

  formatDateYYYYMMDD(isoDate) {
    if (!isoDate) return isoDate;
    return moment(isoDate).format('YYYY-MM-DD');
  },

  formatDateHHmm(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).utcOffset(-180).format("HH:mm");
    return formatted;
  },

  formatDateAndTimeToISODate(date, time, keepUserTZ = false) {
    if (date && time) {
      const fullDate = `${date} ${time}`;
      return moment(fullDate, "YYYY-MM-DD HH:mm").toISOString(keepUserTZ);
    }
    if (date) return date;
    if (time) return time;
    return '';
  },

  getClientTimezoneOffsetString() {
    const currentDate = new Date();
    const offset = currentDate.getTimezoneOffset() * (-1);
    const offsetInHours = (offset / 60);
    return offsetInHours >= 0 ? `+${offsetInHours}` : offsetInHours.toString();
  },

  formatUuid(uuid) {
    if (!uuid || uuid.length <= 6) {
      return uuid;
    }
    let formattedUuid = uuid.substr(0, 3);
    formattedUuid += uuid.substr(uuid.length - 3);
    return formattedUuid.toUpperCase();
  },

  formatUuidWithEllipsis(uuid) {
    if (!uuid || uuid.length <= 6) {
      return uuid;
    }
    let formattedUuid = uuid.substr(0, 3);
    formattedUuid += '...';
    formattedUuid += uuid.substr(uuid.length - 3);
    return formattedUuid.toUpperCase();
  },

  formatPhone(phone) {
    if (!phone || phone.length !== 14) {
      return phone;
    }
    let countryCode = phone.substr(0, 3);
    let areaCode = " (" + phone.substr(3, 2) + ") ";
    let formattedPhone = phone.substr(5, 1) + phone.substr(6, 4) + "-" + phone.substr(phone.length - 4);
    phone = countryCode + areaCode + formattedPhone;
    return phone;
  },

  formatDocument(document) {
    if (!document || document.length !== 11) {
      return document;
    }
    let dot = ".";
    let formattedDocument = document.substr(0, 3) + dot +
      document.substr(3, 3) + dot +
      document.substr(6, 3) + "-" +
      document.substr(document.length - 2);
    document = formattedDocument;
    return document;
  },

  clearPhoneTxt(phone) {
    if (!phone) {
      return phone;
    }
    return phone.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('_', '');
  },

  clearCpfTxt(cpf) {
    if (!cpf) {
      return cpf;
    }
    return cpf.replaceAll('.', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('_', '');
  },

  formatLicensePlate(value) {
    if (value.length !== 8) {
      let plateBegining = value.substr(0, 3);
      let plateEnding = value.substr(value.length - 4);
      value = plateBegining + '-' + plateEnding;
    }
    return value;
  },

  formatTransportModalType(value) {
    if ("URBAN_BUS" === value) {
      return 'Ônibus Urbano'; // intl.formatMessage(TRANSPORT_MODAL_TYPE_URBAN_BUS);
    } else if ("ROAD_BUS" === value) {
      return 'Ônibus Rodoviário'; // intl.formatMessage(TRANSPORT_MODAL_TYPE_ROAD_BUS);
    } else if ("METRO" === value) {
      return 'Metrô'; // intl.formatMessage(TRANSPORT_MODAL_TYPE_METRO);
    } else {
      return value;
    }
  },

  formatTransactionType(value) {
    switch (value) {
      case 'GET_ON_TRANSPORT':
        return 'Pagamento de transporte';
      case 'TOP_UP':
        return 'Recarga';
      default:
        return value;
    }
  },

  formatTransactionPaymentGatewayMne(value) {
    switch (value) {
      case 'EVOM_PASS':
        return 'EvomPass';
      case 'VALE_FACIL':
        return 'Vale Fácil';
      default:
        return value;
    }
  },

  formatPaymentGatewayUserType(paymentGatewayUserType) {
    switch (paymentGatewayUserType) {
      case 'REGULAR':
        return 'Comum';
      case 'WORKER':
        return 'Vale Transporte';
      case 'STUDENT':
        return 'Estudante';
      case 'STUDENT_FREE':
        return 'Estudante Passe Livre';
      case 'ELDERLY':
        return 'Idoso';
      case 'SPECIAL':
        return 'Especial';
      case 'SINGLE_PASS':
        return 'Avulso';
      case 'SINGLE_PASS_PIX':
        return 'PIX';
      case 'SINGLE_PASS_DINHEIRO':
        return 'Dinheiro';
      case 'GENERIC_1':
        return 'Carteira genérica #1';
      case 'GENERIC_2':
        return 'Carteira genérica #2';
      case 'GENERIC_3':
        return 'Carteira genérica #3';
      case 'GENERIC_4':
        return 'Carteira genérica #4';
      case 'GENERIC_5':
        return 'Carteira genérica #5';
      case 'GENERIC_6':
        return 'Carteira genérica #6';
      case 'GENERIC_7':
        return 'Carteira genérica #7';
      case 'GENERIC_8':
        return 'Carteira genérica #8';
      case 'GENERIC_9':
        return 'Carteira genérica #9';
      case 'GENERIC_10':
        return 'Carteira genérica #10';
      default:
        return paymentGatewayUserType;
    }
  },

  formatCrewType(crewType) {
    switch (crewType) {
      case CREW_TYPE.CREW:
        return 'Tripulação';
      case CREW_TYPE.SUPERVISOR:
        return 'Físcal';
      default:
        return crewType;
    }
  },

  getPaymentGatewayUserTypeColor(paymentGatewayUserType) {
    switch (paymentGatewayUserType) {
      case 'REGULAR':      return '#007690';
      case 'WORKER':       return '#00C9E2';
      case 'STUDENT':      return '#009973';
      case 'STUDENT_FREE': return '#5EC283';
      case 'ELDERLY':      return '#AFB42B';
      case 'SPECIAL':      return '#FAF373';
      case 'SINGLE_PASS':  return '#FACB73';
      case 'GENERIC_1':    return '#FF8F00';
      case 'GENERIC_2':    return '#CD5624';
      case 'GENERIC_3':    return '#EC766A';
      case 'GENERIC_4':    return '#FF4B5D';
      case 'GENERIC_5':    return '#D55091';
      case 'GENERIC_6':    return '#BC96E6';
      case 'GENERIC_7':    return '#9E75CC';
      case 'GENERIC_8':    return '#8A5293';
      case 'GENERIC_9':    return '#6F4B7C';
      case 'GENERIC_10':   return '#9F0062';
      default:             return '#BBB';
    }
  },

  formatToReal(value) {
    return value ? `R$ ${value}` : '-';
  },

  formatTransactionStatus(value) {
    switch (value) {
      case 'CREATED':
        return 'Criada';
      case 'AUTHORIZED':
        return 'Autorizada';
      case 'UNAUTHORIZED':
        return 'Não Autorizada';
      case 'FAILED':
        return 'Falha';
      case 'CANCELED':
        return 'Cancelada';
      case 'UNKNOWN_ERROR':
        return 'Erro Desconhecido';
      case 'ROLLBACKED':
        return 'Estornada';
      case 'FULL_REFUND':
        return 'Reembolso total';
      case 'PARTIAL_REFUND':
        return 'Reembolso parcial';
      default:
        return value;
    }
  },

  resolveTransactionStatusColor(value) {
    switch (value) {
      case 'CREATED':
        return 'yellow';
      case 'AUTHORIZED':
        return 'green';
      case 'UNAUTHORIZED':
        return 'red';
      case 'FAILED':
        return 'amber';
      case 'CANCELED':
        return 'orange';
      case 'UNKNOWN_ERROR':
        return 'purple';
      default:
        return value;
    }
  },

  resolveStatusColor(status) {
    if ("CREATED" === status) {
      return "#ef5350";
    } else if ("ISSUED" === status) {
      return "#64dd17";
    } else if ("FAILED" === status) {
      return "#ef5350";
    } else if ("CANCELED" === status) {
      return "#ef5350";
    } else if ("BURNED" === status) {
      return "#ef5350";
    } else {
      return status;
    }
  },

  formatOnboardHardwareType(value) {
    switch (value) {
      case 'VALIDATOR':
        return 'Validador'; // TODO intl
      case 'TURNSTILE_IOT':
        return 'Catraca'; // TODO intl
      case 'MOBIPIX_APP':
        return 'Aplicativo'; // TODO intl
      default:
        return value;
    }
  },

  formatTagContent(tagContent) {
    if (tagContent && tagContent.length === 6) {
      return tagContent.substring(0, 3) + '-' + tagContent.substring(3, 6);
    }
    return 'Não definido'
  },

  formatPricingMatrixWorkday(value) {
    switch (value) {
      case 'WORKDAY':
        return 'Útil';
      case 'SATURDAY':
        return 'Sábado';
      case 'SUNDAY':
        return 'Domingo';
      case 'HOLIDAY':
        return 'Feriado';
      case 'WORKDAY_DAWN':
        return 'Madrugada (útil)';
      default:
        return value;
    }
  },

  formatListItemsFieldName(list, replaceFieldNameFunc) {
    const formatted = [];
    for (var i in list) {
      var item = list[i];
      var formattedItem = {};
      for (var fieldName in item) {
        formattedItem[replaceFieldNameFunc(fieldName)] = item[fieldName];
      }
      formatted.push(formattedItem);
    }
    return formatted;
  },

  formatTripStatus(tripStatus) {
    switch (tripStatus) {
      case 'PENDING':
        return 'Pendente';
      case 'ACTIVE':
        return 'Ativa';
      case 'FINISHED':
        return 'Finalizada';
      case 'ERROR':
        return 'Erro';
      case 'NOT_DONE':
        return 'Não realizada';
      default:
        return tripStatus;
    }
  },

  formatTripDirection(tripDirection) {
    switch (tripDirection) {
      case 'INBOUND':
        return 'Ida';
      case 'OUTBOUND':
        return 'Volta';
      default:
        return tripDirection;
    }
  },

  formatTripDirectionToBackend(tripDirection) {
    switch (tripDirection) {
      case 'Ida':
        return 'INBOUND';
      case 'Volta':
        return 'OUTBOUND';
      default:
        return tripDirection;
    }
  },

  getUserStatusBadge(status) {
    switch (status) {
      case true:  return 'badge badge-success';
      case false: return 'badge badge-danger';
      default:    return 'badge badge-light';
    }
  },

  formatUserStatus(status) {
    switch (status) {
      case true:  return 'Ativo';
      case false: return 'Inativo';
      default:    return status;
    }
  },

  formatIdentificationMethodType(identificationMethodType) {
    switch (identificationMethodType) {
      case 'QRCODE_MOBILE':
        return 'QR Code Aplicativo';
      case 'QRCODE_CARD':
        return 'QR Code Cartão';
      case 'QRCODE_BRACELET':
        return 'QR Code Pulseira';
      case 'QRCODE_PAPER':
        return 'QR Code Impresso';
      case 'NFC_MOBILE':
        return 'NFC Aplicativo';
      case 'NFC_CARD':
        return 'NFC Cartão';
      case 'NFC_BRACELET':
        return 'NFC Pulseira';
      case 'ANONYMOUS':
        return 'Anônimo';
      default:
        return identificationMethodType;
    }
  },

  formatTransactionMode(transactionMode) {
    switch (transactionMode) {
      case 'ACTIVE':
        return 'Ativa';
      case 'PASSIVE':
        return 'Passiva';
      default:
        return transactionMode;
    }
  },

  formatFareTimeBasedIntegrationStatus(fareTimeBasedIntegrationStatus) {
    switch (fareTimeBasedIntegrationStatus) {
      case 'CREATED':
        return 'Criada';
      case 'IN_PROGRESS':
        return 'Em andamento';
      case 'COMPLETED':
        return 'Completa';
      default:
        return fareTimeBasedIntegrationStatus;
    }
  },

  reducePaymentArrangementDatafromPaymentStats(paymentStats) {
    let totalPaymentArrangementsAuthorizedTransactionAmount = 0;
    let totalPaymentArrangementsAuthorizedTransactions = 0;

    if (Array.isArray(paymentStats)) {
      totalPaymentArrangementsAuthorizedTransactionAmount = paymentStats.reduce((accumulator, currentValue) => (
        currentValue.pgaGuiBrCode && currentValue.pgaGuiBrCode !== 'br.gov.bcb.pix' && currentValue.pgaGuiBrCode !== 'br.gov.bcb.real' ?
          accumulator + currentValue.transactionsTotalAmount :
          accumulator
      ), 0);

      totalPaymentArrangementsAuthorizedTransactions = paymentStats.reduce((accumulator, currentValue) => (
        currentValue.pgaGuiBrCode && currentValue.pgaGuiBrCode !== 'br.gov.bcb.pix' && currentValue.pgaGuiBrCode !== 'br.gov.bcb.real' ?
          accumulator + currentValue.transactionsTotal :
          accumulator
      ), 0);
    }

    return { totalPaymentArrangementsAuthorizedTransactionAmount, totalPaymentArrangementsAuthorizedTransactions };
  },

  fillTransactionCountByTime(transactionCountByTime) {
    const filledTransactionCountByTime = [];

    if (Array.isArray(transactionCountByTime)) {
      for (let hour = 0; hour < 24; hour++) {
        const currentTransactionCount = transactionCountByTime.find(transactionCount => transactionCount.time === hour);

        if (currentTransactionCount) {
          filledTransactionCountByTime.push(currentTransactionCount);
        } else {
          filledTransactionCountByTime.push({ time: hour });
        }
      }
    }

    return filledTransactionCountByTime;
  },

  getTransportOperatorIdsFilterQueryParam(transportOperatorIdsArray) {
    if (transportOperatorIdsArray?.length > 0) {
      const reducedTransportOperatorIdsArray = transportOperatorIdsArray?.reduce?.((previousValue, currentValue, index) => {
        if (index === 0) {
          return currentValue;
        } else {
          return `${previousValue},${currentValue}`;
        }
      }, '');
      return `&transitOperatorIds=${encodeURIComponent(reducedTransportOperatorIdsArray)}`;
    }
    return '';
  },

  getTransportOperatorNamesFilterQueryParam(transportOperatorNamesArray) {
    if (transportOperatorNamesArray?.length > 0) {
      const reducedTransportOperatorNamesArray = transportOperatorNamesArray?.reduce?.((previousValue, currentValue, index) => {
        if (index === 0) {
          return currentValue;
        } else {
          return `${previousValue},${currentValue}`;
        }
      }, '');
      return `&transitOperatorNames=${encodeURIComponent(reducedTransportOperatorNamesArray)}`;
    }
    return '';
  },

  getFilterQueryParam(queryParam, values) {
    if (!values || values.length <= 0) return '';

    const reducedVehicles = values.reduce(
      (previousValue, currentValue, index) => index === 0 ? currentValue : `${previousValue},${currentValue}`, ''
    );

    return `&${queryParam}=${encodeURIComponent(reducedVehicles)}`;
  },

  formatBigNumberToHexWithPadding(number) {
    if (!number) return number;
    const bn = new BigNumber(number);
    const longHexValue = '000000000000000' + bn.abs().toString(16);
    return longHexValue.substr(longHexValue.length - 16);
  },

  formatHardwareId(hardwareId) {
    return hardwareId ? `0x${this.formatBigNumberToHexWithPadding(hardwareId)} (${hardwareId})` : null;
  },

  getDurationFromNow(ISOStringDate) {
    if (!ISOStringDate) return null;
    return intervalToDuration({ start: new Date(ISOStringDate), end: Date.now() });
  },

  getFormattedDuration(duration) {
    if (!duration) return null;
    return formatDuration(duration, {
      format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
      locale: ptBrLocale,
    });
  },

  compareDurations(duration1, duration2) {
    if (!duration1 && !duration2) return 0;
    if (!duration1) return -1;
    if (!duration2) return 1;

    for (const durationComponent of ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']) {
      if (duration1[durationComponent] > duration2[durationComponent]) return 1;
      if (duration1[durationComponent] < duration2[durationComponent]) return -1;
    }

    return 0;
  },

  flattenObject(toBeFlattenedObject) {
    const flattenedObject = {};
    const toBeFlattenedObjectProperties = Object.keys(toBeFlattenedObject);

    for (const property of toBeFlattenedObjectProperties) {
      if (toBeFlattenedObject[property] !== null && typeof toBeFlattenedObject[property] === 'object' && !Array.isArray(toBeFlattenedObject[property])) {
        const flattenedSubObject = this.flattenObject(toBeFlattenedObject[property]);
        const flattenedSubObjectProperties = Object.keys(flattenedSubObject);

        for (const flattenedSubObjectProperty of flattenedSubObjectProperties) {
          flattenedObject[`${property}.${flattenedSubObjectProperty}`] = flattenedSubObject[flattenedSubObjectProperty];
        }
      } else {
        flattenedObject[property] = toBeFlattenedObject[property];
      }
    }

    return flattenedObject;
  },

  formatGetTripType(value) {
    switch (value) {
      case 'ACTIVE':
        return GET_ACTIVE_TRIPS;
      case 'PENDING':
        return GET_PENDING_TRIPS;
      case 'FINISHED':
        return GET_FINISHED_TRIPS;
      case 'ERROR':
        return GET_ERROR_TRIPS;
      case 'NOT_DONE':
        return GET_NOT_DONE_TRIPS;
      default:
        return value;
    }
  },

  formatRequestTripType(value) {
    switch (value) {
      case 'ACTIVE':
        return REQUEST_GET_ACTIVE_TRIPS;
      case 'PENDING':
        return REQUEST_GET_PENDING_TRIPS;
      case 'FINISHED':
        return REQUEST_GET_FINISHED_TRIPS;
      case 'ERROR':
        return REQUEST_GET_ERROR_TRIPS;
      case 'NOT_DONE':
        return REQUEST_GET_NOT_DONE_TRIPS;
      default:
        return value;
    }
  },

  formatErrorTripType(value) {
    switch (value) {
      case 'ACTIVE':
        return REQUEST_GET_ACTIVE_TRIPS_FAILURE;
      case 'PENDING':
        return REQUEST_GET_PENDING_TRIPS_FAILURE;
      case 'FINISHED':
        return REQUEST_GET_FINISHED_TRIPS_FAILURE;
      case 'ERROR':
        return REQUEST_GET_ERROR_TRIPS_FAILURE;
      case 'NOT_DONE':
        return REQUEST_GET_NOT_DONE_TRIPS_FAILURE;
      default:
        return value;
    }
  },

  formatRoleAuthorities(value) {
    switch (value) {
      case ROLE_AUTHORITIES.ROLE_PAYMENT_GATEWAY_USER:
        return 'Usuário Arranjo de Pagamento';
      case ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER:
        return 'Usuário Operador de transporte';
      case ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER:
        return 'Usuário Agência de trânsito';
      default:
        return value;
    }
  },

  getPermissionAuthoritiesList(value) {
    switch (value) {
      case ROLE_AUTHORITIES.ROLE_PAYMENT_GATEWAY_USER:
        return paymentGatewayUserPermissionAuthorities;
      case ROLE_AUTHORITIES.ROLE_TRANSIT_OPERATOR_USER:
        return transitOperatorUserPermissionAuthorities;
      case ROLE_AUTHORITIES.ROLE_TRANSIT_AGENCY_USER:
        return transitAgencyUserPermissionAuthorities;
      default:
        return value;
    }
  },

  /**
   * Busca o indice do objeto em um array de colunas de MUI Datatable em que name = key.
   * Caso nao haja um nome que corresponda a chave pesquisada, a funcao retorna undefined.
   * @param {string} key - A chave a ser pesquisada em columns (comparando pelo atributo "name").
   * @param {Array<Object>} columns - Array de objects no estilo de MUI Datatable (todas colunas devem ter prop. "name"!).
   * @returns {number|undefined} O indice inteiro de key se esta em columns, caso contrario devolve undefined.
   */
  getColumnIndex(key, columns) {
    for (let index = 0; index < columns.length; index++) {
      if (columns[index].name === key) {
        return index;
      }
    }
    return undefined;
  },

  /**
   * Busca a lista de filtros ativos no filterList de MUI Datatable com base em uma chave "key" que deve pertencer
   * a columns. No caso de "key" ser igual a "serverTime", retorna um Array de objetos Moment.
   * Caso nao haja tal chave em columns, a funcao retorna undefined.
   * @param {string} key - A chave a ser pesquisada em columns (comparando pelo atributo "name").
   * @param {Array<Object>} columns - Array de objects no estilo de MUI Datatable (todas colunas devem ter prop. "name"!).
   * @param {Array<Array<>>} filterList - Array de Array contendo todos os filtros ativos.
   * @returns {Array<string>|Array<moment>|undefined} O array de filtros ativos se key esta em columns, caso contrario undefined.
   */
  getColumnFilter(key, columns, filterList) {
    const index = this.getColumnIndex(key, columns);
    if (index) {
      return filterList[index];
    }
    return undefined;
  },

  /**
   * Gera a query string a ser passada para o servidor.
   * @param {string} field - Campo da query.
   * @param {Object|any} operator - Seleciona o comportamento da funcao (geralmente string vazia para o caso else).
   * @param {Array<string>|Array<moment>} filterItems - Array com os filtros ativos para o campo fornecido.
   * @returns {string} Uma query string para o servidor.
   */
  reduceFilter(field, operator, filterItems) {
    if (typeof operator == 'object') {
      return `${field}.${operator[0]}=${moment(filterItems[0]).format()}&${field}.${operator[1]}=${moment(
        filterItems[1],
      ).format()}`;
    } else {
      return filterItems.reduce(
        (totalValue, currentValue) => `${totalValue}${field}.${operator}=${encodeURIComponent(currentValue)}&`,
        '',
      );
    }
  },

  /**
   * Realiza os procedimentos padrao para os filtros ativos de uma coluna de columns onde column.name == key.
   * Se os filtros ativos existem, ele concatena mais uma query (gerada por key) a propriedade "str" de serverSideFilterObj
   * e adiciona o par key-valor do filtro a um objeto URLSearchParams para gerar a query da URL.
   * @param {string} key - A chave a ser pesquisada em columns (comparando pelo atributo "name").
   * @param {Array<Object>} columns - Array de objects no estilo de MUI Datatable (todas colunas devem ter prop. "name"!).
   * @param {Array<Array<>>} filterList - Array de Array contendo todos os filtros ativos.
   * @param {URLSearchParams} urlParams - Objeto que armazena os pares chave-valor para gerar a query da URL.
   * @param {Object} serverSideFilterParamName - Objeto que armazena a query para o servidor na propriedade "str".
   */
  mountServerSideUrlFilter(key, columns, filterList, urlParams, serverSideFilterParamName) {
    const activeFilters = this.getColumnFilter(key, columns, filterList);
    if (activeFilters?.length) {
      serverSideFilterParamName.str += this.reduceFilter(key, 'equals', activeFilters);
      activeFilters.forEach(e => urlParams.append(key, e));
    }
  },

};
