import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import FarePricesReducers from './FarePricesReducers';
import TransportOperatorReducers from './TransportOperatorReducers';
import PaymentGatewayReducers from './PaymentGatewayReducers';
import TransitAgencyReducers from './TransitAgencyReducers';
import TransactionReducers from './TransactionReducers';
import VehicleReducers from './VehicleReducers';
import RouteReducers from './RouteReducers';
import EnrollmentsReducers from './EnrollmentsReducers';
import ConsumptionByTripReportReducers from './ConsumptionByTripReportReducers';
import ConsumptionByRouteReportReducers from './ConsumptionByRouteReportReducers';
import UserReducer from './UserReducer';
import CrewReducer from './CrewReducer';
import TripReducers from './TripReducers';
import AutocompletesReducers from './AutocompletesReducers';
import PIXMonitor from './PIXMonitorReducers';
import TripDashboardReducers from './TripDashboardReducer';
import RouteGroupsReducer from './RouteGroupsReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  fare: FarePricesReducers,
  transportOperator: TransportOperatorReducers,
  paymentGateway: PaymentGatewayReducers,
  transitAgency: TransitAgencyReducers,
  transaction: TransactionReducers,
  vehicle: VehicleReducers,
  route: RouteReducers,
  routeGroups: RouteGroupsReducer,
  enrollment: EnrollmentsReducers,
  consumptionByTripReport: ConsumptionByTripReportReducers,
  consumptionByRouteReport: ConsumptionByRouteReportReducers,
  user: UserReducer,
  crew: CrewReducer,
  trip: TripReducers,
  autocompletes: AutocompletesReducers,
  PIXMonitor: PIXMonitor,
  tripDashboard: TripDashboardReducers,
});
