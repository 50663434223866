import axios from 'axios';
import { signOut } from 'actions/Auth';
import { store } from 'MainApp';
import { toast } from 'react-toastify';

const mobipixApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-CLIENT-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

mobipixApi.interceptors.request.use(
  request => {
    const jwtToken = localStorage.getItem('token');

    if (jwtToken && !request.headers['Authorization'])
      request.headers['Authorization'] = `Bearer ${jwtToken}`;

    return request;
  },
  error => Promise.reject(error)
);

mobipixApi.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      if (store.getState().auth.isLoggedIn) toast.error('Usuário não autenticado');
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

export default mobipixApi;
