import {
  SIGN_OUT,
  GET_ROUTES_AUTOCOMPLETE_OPTIONS,
  GET_FARE_TABLES_AUTOCOMPLETE_OPTIONS,
  GET_DRIVERS_AUTOCOMPLETE_OPTIONS,
  GET_VEHICLES_AUTOCOMPLETE_OPTIONS,
  GET_PAYMENT_GATEWAYS_AUTOCOMPLETE_OPTIONS,
  GET_TRANSIT_OPERATORS_AUTOCOMPLETE_OPTIONS,
  GET_TRANSIT_AGENCIES_AUTOCOMPLETE_OPTIONS,
} from "constants/ActionTypes";

const INIT_STATE = {
  routesAutocompleteOptions: [],
  fareTablesAutocompleteOptions: [],
  driversAutocompleteOptions: [],
  vehiclesAutocompleteOptions: [],
  paymentGatewaysAutocompleteOptions: [],
  transitOperatorsAutocompleteOptions: [],
  transitAgenciesAutocompleteOptions: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROUTES_AUTOCOMPLETE_OPTIONS: {
      const routesAutocompleteOptions = action.payload;
      return {
        ...state,
        routesAutocompleteOptions: routesAutocompleteOptions
      }
    }

    case GET_FARE_TABLES_AUTOCOMPLETE_OPTIONS: {
      return {
        ...state,
        fareTablesAutocompleteOptions: action.payload,
      }
    }

    case GET_DRIVERS_AUTOCOMPLETE_OPTIONS: {
      const driversAutocompleteOptions = action.payload;
      return {
        ...state,
        driversAutocompleteOptions: driversAutocompleteOptions
      }
    }

    case GET_VEHICLES_AUTOCOMPLETE_OPTIONS: {
      const vehiclesAutocompleteOptions = action.payload;
      return {
        ...state,
        vehiclesAutocompleteOptions: vehiclesAutocompleteOptions
      }
    }

    case GET_PAYMENT_GATEWAYS_AUTOCOMPLETE_OPTIONS: {
      return {
        ...state,
        paymentGatewaysAutocompleteOptions: action.payload
      }
    }

    case GET_TRANSIT_OPERATORS_AUTOCOMPLETE_OPTIONS: {
      return {
        ...state,
        transitOperatorsAutocompleteOptions: action.payload
      }
    }

    case GET_TRANSIT_AGENCIES_AUTOCOMPLETE_OPTIONS: {
      return {
        ...state,
        transitAgenciesAutocompleteOptions: action.payload
      }
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }
  }
}
